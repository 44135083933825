import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackType, FeedbackAnswerType, User, RoleBar } from 'types/types';
import { useUsers } from 'model/users';

import Blockquote from 'components/Blockquote';
import SmileyRating from 'components/SmileyRating';
import Button from 'components/Button';
import ExportsButton from 'blocks/ExportsButton';

import { User as UserIcon } from 'assets/icons';
import { Reviews } from 'assets/undraw';
import { useSessionFeedbacks } from 'model/productions';

const NB_ELEMENTS_PARTIAL = 5;

export type FeebackViewProps = {
  sessionId: string;
  showExportButton?: boolean;
  anonymous?: boolean;
  role: RoleBar;
};

const getRandomElements = (array: any[], nb: number) => {
  if (array.length < nb) return array;
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, nb);
};

const TableResult = ({
  data,
  partial,
  transformColumnTitle = (value) => value,
  anonymous = false,
}: {
  data: Record<string, string[]>;
  partial: boolean;
  transformColumnTitle?: (value: string) => string;
  anonymous?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {Object.entries(data).map(([value, userIds], index) => {
        return (
          <div key={index} className="flex flex-col border border-primary-soft">
            <div className="flex flex-row items-center justify-between bg-primary-soft py-2 px-4 text-center text-xl text-primary-strong">
              <p className="font-semibold">{transformColumnTitle(value)}</p>
              <span className="flex flex-row items-center">
                <UserIcon className="h-5 w-5 stroke-2" />
                <p>{userIds.length}</p>
              </span>
            </div>
            {!anonymous ? (
              <div className="p-2 text-center">
                {userIds
                  .slice(0, partial ? NB_ELEMENTS_PARTIAL : undefined)
                  .map((userId, index) => {
                    return (
                      <p key={index} className="capitalize">
                        {userId}
                      </p>
                    );
                  })}
                {partial && userIds.length > NB_ELEMENTS_PARTIAL ? (
                  <p className="font-semibold">
                    {t('common:andXother', {
                      count: userIds.length - NB_ELEMENTS_PARTIAL,
                    })}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const Feedback = ({
  anonymous,
  feedback,
  users,
}: {
  anonymous: boolean;
  feedback: FeedbackType;
  users: Record<string, User>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [refreshIndex, setRefreshIndex] = useState(1);
  const filteredFeedbackAnswers =
    feedback.type === 'string'
      ? feedback.answers.filter((answer) => answer.value?.length > 1)
      : feedback.answers;
  const [partial, setPartial] = useState(
    filteredFeedbackAnswers.length >= NB_ELEMENTS_PARTIAL
  );
  const answers = useMemo(() => {
    let newAnswers: any[] = filteredFeedbackAnswers;
    if (feedback.type === 'string' && partial && refreshIndex > 0) {
      newAnswers = getRandomElements(
        filteredFeedbackAnswers,
        NB_ELEMENTS_PARTIAL
      );
    }
    return newAnswers;
  }, [partial, feedback.type, refreshIndex, filteredFeedbackAnswers]);

  const renderBlock = () => {
    switch (feedback.type) {
      case 'number':
        const numberData: Record<string, string[]> = answers.reduce(
          (current, answer) => {
            const userName =
              users[answer.userId]?.name || t('common:unknowUser');
            if (current[answer.value]?.length > 0) {
              current[answer.value].push(userName);
            } else {
              current[answer.value] = [userName];
            }
            return current;
          },
          {}
        );
        return (
          <div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="flex flex-col items-center justify-center lg:flex-row">
                <h3 className="font mr-4 font-semibold">
                  {t('common:average')}
                </h3>
                <SmileyRating
                  value={feedback.meta.moyenne}
                  showValue
                  readOnly
                />
              </div>
              <div className="flex flex-col items-center justify-center lg:flex-row">
                <h3 className="font mr-4 font-semibold">
                  {t('common:participants')}
                </h3>
                <div className="flex h-12 w-12 flex-row items-center rounded-full bg-primary-soft">
                  <p className="mx-auto text-2xl font-semibold text-primary-strong">
                    {feedback.answers.length}
                  </p>
                </div>
              </div>
            </div>
            {!partial && !anonymous ? (
              <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <TableResult
                  data={numberData}
                  partial={partial}
                  transformColumnTitle={(value) =>
                    `${t('common:score')} ${value}`
                  }
                />
              </div>
            ) : null}
          </div>
        );
      case 'string':
        return (
          <div className="space-y-2">
            {answers.map((answer, index) => {
              const username =
                users[answer.userId]?.name || t('common:unknowUser');
              return (
                <Blockquote
                  key={index}
                  quote={answer.value}
                  username={anonymous ? undefined : username}
                />
              );
            })}
          </div>
        );
      case 'boolean':
        const booleanData: Record<string, string[]> = answers.reduce(
          (current, answer) => {
            const label = answer.value ? t('common:yes') : t('common:no');
            const userName =
              users[answer.userId]?.name || t('common:unknowUser');
            if (current[label]?.length > 0) {
              current[label].push(userName);
            } else {
              current[label] = [userName];
            }
            return current;
          },
          {}
        );
        return (
          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
            <TableResult
              data={booleanData}
              partial={partial}
              anonymous={anonymous}
            />
          </div>
        );
    }
  };
  return (
    <div>
      <div className="mb-4 flex flex-row items-center text-xl font-semibold">
        {feedback.description}
        {(!anonymous || feedback.type === 'string') &&
        (partial || answers.length >= NB_ELEMENTS_PARTIAL) ? (
          <Button
            className="ml-4 shrink-0"
            text={partial ? t('common:seeMore') : t('common:seeLess')}
            size="sm"
            onClick={() => setPartial((partial) => !partial)}
          />
        ) : null}
        {!anonymous && partial && feedback.type === 'string' ? (
          <Button
            className="ml-4 shrink-0"
            text={t('common:refresh')}
            size="sm"
            design="secondary"
            onClick={() => setRefreshIndex((index) => index + 1)}
          />
        ) : null}
      </div>
      {renderBlock()}
    </div>
  );
};

type AnswerAlternativeType =
  | FeedbackAnswerType<string>
  | FeedbackAnswerType<number>
  | FeedbackAnswerType<boolean>;

const FeebackView = ({
  sessionId,
  role,
  showExportButton = true,
  anonymous = true,
}: FeebackViewProps): JSX.Element => {
  const { t } = useTranslation();
  const feedbacks = useSessionFeedbacks(sessionId);
  const [users] = useUsers<true>(
    feedbacks?.reduce(
      (userIds: Record<string, true>, feedback: FeedbackType) => {
        feedback.answers.forEach((answer: AnswerAlternativeType) => {
          if (answer.userId) {
            userIds[answer.userId] = true;
          }
        });
        return userIds;
      },
      {}
    ) || {}
  );

  const hasFeedback = useMemo(
    () =>
      feedbacks &&
      feedbacks.filter((feedback) => feedback.answers.length).length > 0,
    [feedbacks]
  );

  return (
    <div>
      <div className="mb-10 flex flex-row items-center">
        <h1 className="flex-grow text-3xl font-semibold">
          {t('common:participantsFeedback')}
        </h1>
        {showExportButton && hasFeedback ? (
          <ExportsButton
            sessionId={sessionId}
            showAdminExports={role === 'superadmin'}
            showFeedbackExport
          />
        ) : null}
      </div>
      <div className="space-y-16 pb-10">
        {hasFeedback ? (
          feedbacks &&
          feedbacks
            .filter((feedback) => feedback.answers.length)
            .map((feedback, index) => {
              return (
                <Feedback
                  key={index}
                  feedback={feedback}
                  users={users}
                  anonymous={anonymous}
                />
              );
            })
        ) : (
          <>
            <h2 className="mt-10 px-5 text-center text-xl">
              {t('sessions:noFeedback')}
            </h2>
            <Reviews className="mx-auto mt-10 w-80 fill-current text-primary md:w-96" />
          </>
        )}
      </div>
    </div>
  );
};

export default FeebackView;
