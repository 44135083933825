import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MenuButtons, { Action } from 'components/MenuButtons';
import Button from 'components/Button';

import { exportCommunityMembers } from 'services/export';

import { ChevronUp, Table } from 'assets/icons';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

export type CommunityExportsButtonProps = {
  communityId: string;
  secondary?: boolean;
};

type ExportState = 'exporting' | 'error' | 'null';

const ExportsButton = ({
  communityId,
  secondary = false,
}: CommunityExportsButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const actions: Action[] = [];
  const [exportState, setExportState] = useState<ExportState>('null');

  actions.push({
    type: 'button',
    onClick: () => exportCommunityMembers(communityId),
    label: t('communities:exportMembers'),
    icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
  });

  const modalTitle =
    exportState === 'exporting'
      ? t('sessions:ExportInProgress')
      : exportState === 'error'
      ? t('sessions:ExportError')
      : null;

  const modalBody =
    exportState === 'exporting' ? (
      <Spinner className="w-16 h-16 text-primary" />
    ) : exportState === 'error' ? (
      <p>{t('sessions:ExportErrorRetryDescription')}</p>
    ) : null;

  return (
    <>
      <Modal
        open={exportState !== 'null'}
        title={modalTitle}
        body={
          <div className="flex flex-col items-center min-h-[94px]">
            {modalBody}
            {exportState === 'error' ? (
              <Button
                text={t('common:Close')}
                onClick={() => setExportState('null')}
              />
            ) : null}
          </div>
        }
      />
      <MenuButtons
        menuButton={
          <Button
            design={(secondary && 'secondary') || undefined}
            size={secondary ? 'sm' : 'md'}
            disabled={actions.length === 0}
            text={t('sessions:exportData')}
            icon={<ChevronUp className="rotate-180 stroke-2" />}
            iconPlacement="right"
          />
        }
        size="sm"
        verticalGap={false}
        iconPlacement="left"
        actions={actions}
      />
    </>
  );
};

export default ExportsButton;
