import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { LOGIN_PATH } from 'constants/AppConfig';
import { domainUrl } from 'config';

import {
  getSessionIdFromAccessCode,
  userIsParticipant,
  setSessionState,
  createTestSession,
  userIsAttendee,
} from 'model/sessions';

import { Mode } from 'types/ui';
import {
  Session,
  SessionTemplate,
  RoleBar,
  ActivitiesMeta,
  UserType,
  Activity,
  SessionStatusState,
  Group,
} from 'types/types';
import { WithId } from 'types/typesUtils';
import { Nullable } from 'types/typesUtils';
import { HistoryState } from 'utils/history';

import SessionRouter from './router';
import Layout from './Layout';
import ActivitiesList from './ActivitiesList';

import LoadingScreen from 'screens/LoadingScreen';
import AdminActivitySettings from 'screens/AdminSettingsScreen/containers/AdminActivitySettings';
import ParametersPane from 'screens/ParametersPane';

import MainBar from 'components/MainBar';
import Button from 'components/Button';
import ActionsButton from 'components/ActionButton';
import Listbox from 'components/Listbox';
import Modal from 'components/Modal';
import GroupHeader from 'blocks/GroupHeader';

import {
  Adjustments,
  BetterLink as LinkIcon,
  Check,
  Eye,
  EyeOff,
  UserGroup,
  Home,
  DocumentText,
  PresentationChart,
  Wrench,
  Hand,
  LightBulb,
  PlayCircle,
  Beaker,
} from 'assets/icons';
import { useGroup } from 'model/dataHooks';
import { callFacilitator, getGroup } from 'model/groupManagement';
import { findRelevantUser, isGlobalAdmin } from 'model/users';
import { usePreviousCombinator } from 'utils/utils';
import { activateActivity } from 'model/activitiesManagement';
import YesNoModal from 'components/YesNoModal';
import QrCodeActionButton from './blocks/QrCodeActionButton';

const roleToModes: Record<RoleBar, Mode[]> = {
  superadmin: ['editor', 'facilitator'],
  organizer: ['editor', 'facilitator'],
  facilitator: ['facilitator'],
  participant: ['participant'],
};

type GroupDataActionProps = {
  sessionId: string;
  userId: string;
  activity: Activity;
  render: (group: WithId<Group> | undefined) => JSX.Element;
};
const GroupDataAction = ({
  sessionId,
  userId,
  activity,
  render,
}: GroupDataActionProps) => {
  const [group, groupId] = useGroup(sessionId, userId, activity);
  return render(groupId && group ? { id: groupId, ...group } : undefined);
};

const SessionLiveContext = React.createContext<{
  selectedGroupId: string | undefined;
  setSelectedGroupId: (groupId: string | undefined) => void;
}>({
  selectedGroupId: undefined,
  setSelectedGroupId: () => {},
});

const computeMeetingNameFrom = (
  sessionId: string | null,
  groupNumberOfEnabledActivity: number | undefined | null,
  mode: Mode | undefined,
  facilitatorSelectedGroupNumber: number | null
): string => {
  if (mode === 'participant') {
    if (groupNumberOfEnabledActivity) {
      return `${sessionId}-group-${groupNumberOfEnabledActivity}`;
    } else {
      return `${sessionId}-plenary`;
    }
  } else if (mode === 'facilitator' || mode === 'preview') {
    if (facilitatorSelectedGroupNumber) {
      return `${sessionId}-group-${facilitatorSelectedGroupNumber}`;
    } else {
      return `${sessionId}-plenary`;
    }
  } else {
    return `${sessionId}-plenary`;
  }
};

const SessionScreen = ({
  userId,
  userType,
  userName,
  userEmail,
  role,
  switchToHome,
  switchToDetails,
  switchToDashboard,
  showGlobalSettings,
  sessionInTest,
}: {
  userId: string;
  userType: UserType;
  userName: string;
  userEmail: string;
  role: RoleBar;
  switchToHome: () => void;
  switchToDetails: () => void;
  switchToDashboard: () => void;
  showGlobalSettings: () => void;
  sessionInTest: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [activitySettingsName, setActivitySettingsName] = useState<
    string | null
  >(null);

  const [mode, setMode] = useState<Mode | null>(null);
  const [previousMode, setPreviousMode] = useState<Mode | null>(null);

  const location = useLocation<HistoryState>();
  const { accessCode } = useParams<{ accessCode: string }>();

  const { pathname, search } = location;

  const query = new URLSearchParams(search);

  let requestedMode = query.get('modeRequested');
  const withSettings = query.get('withSettings');
  const parts = pathname.split('/');
  const selection = {
    sessionPath: parts[1],
    selectedActivity: parts[2],
    selectedScreen: parts[3],
  };

  if (selection.selectedScreen === 'facilitate') {
    requestedMode = 'facilitator';
  }

  useEffect(() => {
    if (!userId) {
      history.push(LOGIN_PATH, {
        from: pathname,
        message: t('common:messageLoginBeforeSession'),
      });
    }
    if (accessCode) {
      getSessionIdFromAccessCode(accessCode).then((sessionId) => {
        if (!sessionId) {
          history.push(`/home`, {
            error:
              'This session link does not exist. Did you copy it correctly?',
          });
        } else {
          setSessionId(sessionId);
        }
      });
    }
  }, [accessCode, userId, history, pathname, t]);

  const loadArray = sessionId
    ? [
        {
          path: `/sessionsNext/${sessionId}`,
        },
        {
          path: `/sessionsNextTemplates/${sessionId}`,
        },
        {
          path: `/sessionsNextData/${sessionId}/state`,
        },
        {
          path: `/sessionsNextData/${sessionId}/enabledActivities`,
        },
        {
          path: `/sessionsNextData/${sessionId}/activitiesMeta`,
        },
        {
          path: `sessionsNextData/${sessionId}/users/participants/${userId}`,
        },
        {
          path: `sessionsNextData/${sessionId}/users/facilitators/${userId}`,
        },
        {
          path: `sessionsNextData/${sessionId}/users/organizers/${userId}`,
        },
        {
          path: `/sessionsNextData/${sessionId}/enableExpertMode`,
        },
        {
          path: `/sessionsNextData/${sessionId}/inAssistance`,
        },
      ]
    : [];
  useFirebaseConnect(loadArray);

  const session: Nullable<Session> = useSelector(
    (state: any) => sessionId && state.firebase.data.sessionsNext?.[sessionId]
  );

  const sessionTemplate: Nullable<SessionTemplate> = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const enabledActivities: Nullable<Record<string, boolean>> = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextData?.[sessionId]?.enabledActivities) ||
      {}
  );

  const sessionState: Nullable<SessionStatusState> = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionsNextData?.[sessionId].state
  );

  const activitiesMeta: Nullable<ActivitiesMeta> = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextData?.[sessionId]?.activitiesMeta) ||
      {}
  );

  const isFacilitator: Nullable<boolean> = useSelector(
    (state: any) =>
      sessionId &&
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.facilitators?.[
        userId
      ]
  );

  const isOrganizer: Nullable<boolean> = useSelector(
    (state: any) =>
      sessionId &&
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.organizers?.[
        userId
      ]
  );

  const sessionExpertMode: boolean = useSelector((state: any) => {
    const expertModeState =
      sessionId &&
      state.firebase.data.sessionsNextData?.[sessionId]?.enableExpertMode;
    return expertModeState !== null ? expertModeState : true;
  });

  const sessionInAssistance: boolean = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextData?.[sessionId]?.inAssistance) ||
      false
  );

  const orderedActivities = useMemo(() => {
    return (
      isLoaded(sessionTemplate) &&
      _.sortBy(
        Object.values(sessionTemplate?.activities || {}),
        (el) => el.index
      )
    );
  }, [sessionTemplate]);

  const isFacilitatorLoaded = isLoaded(isFacilitator);
  const isOrganizerLoaded = isLoaded(isOrganizer);
  const isFacilitatorEmpty = isEmpty(isFacilitator);
  const sessionLoaded = isLoaded(session);
  const enabledActivitiesLoaded = isLoaded(enabledActivities);

  const showConceptionMode =
    sessionExpertMode || (sessionInAssistance && isGlobalAdmin(userType));

  const smoothOnboarding =
    !['not_started', 'live'].includes(sessionState || 'kickoff') &&
    !showConceptionMode &&
    !sessionInTest;

  useEffect(() => {
    if (
      userId &&
      sessionId &&
      sessionLoaded &&
      isFacilitatorLoaded &&
      role === 'participant'
    ) {
      console.log('Make Participant');
      userIsParticipant(userId, sessionId);
    }
  }, [
    userId,
    sessionId,
    session?.communityId,
    isFacilitator,
    role,
    sessionLoaded,
    isFacilitatorEmpty,
    isFacilitatorLoaded,
  ]);

  useEffect(() => {
    if (
      userId &&
      sessionId &&
      sessionLoaded &&
      enabledActivitiesLoaded &&
      isFacilitatorLoaded &&
      role === 'participant' &&
      enabledActivities?.['share']
    ) {
      console.log('Make Attendee');
      userIsAttendee(userId, sessionId, session.communityId!);
    }
  }, [
    userId,
    sessionId,
    session?.communityId,
    enabledActivitiesLoaded,
    enabledActivities,
    isFacilitator,
    role,
    sessionLoaded,
    isFacilitatorEmpty,
    isFacilitatorLoaded,
  ]);

  useEffect(() => {
    if (
      session?.scheduledAt &&
      isFacilitatorLoaded &&
      isOrganizerLoaded &&
      !mode
    ) {
      if (role !== 'participant') {
        if (
          showConceptionMode &&
          _.includes(roleToModes[role], 'editor') &&
          ((session.scheduledAt[0] > Date.now() &&
            requestedMode !== 'facilitator') ||
            requestedMode === 'editor')
        ) {
          setMode('editor');
          if (withSettings) {
            showGlobalSettings();
          }
        } else {
          if (smoothOnboarding) {
            setMode('preview');
            setPreviousMode('facilitator');
          } else {
            setMode('facilitator');
          }
        }
      } else {
        setMode('participant');
      }
    }
  }, [
    role,
    session?.scheduledAt,
    mode,
    requestedMode,
    withSettings,
    isFacilitatorLoaded,
    isOrganizerLoaded,
    showGlobalSettings,
    showConceptionMode,
    smoothOnboarding,
  ]);

  const productions = _.mapValues(
    sessionTemplate?.activities,
    (activity) => activity.productions || {}
  );

  const hideModal = () => {
    setActivitySettingsName(null);
  };

  const viewActivitySettings = (activityName: string) =>
    setActivitySettingsName(activityName);

  const [impersonatedUserId, setImpersonatedUserId] = useState(userId);
  const [selectedGroupIdByActivities, setSelectedGroupIdByActivities] =
    useState<Record<string, string | undefined> | undefined>(undefined);

  const selectedGroupId = useMemo(() => {
    return (
      selectedGroupIdByActivities?.[selection.selectedActivity] || undefined
    );
  }, [selectedGroupIdByActivities, selection.selectedActivity]);

  const setSelectedGroupId = useMemo(() => {
    return (newGroupId: string | undefined): void => {
      setSelectedGroupIdByActivities((state) => {
        const newState = Object.assign({}, state);
        newState[selection.selectedActivity] = newGroupId;
        return newState;
      });
    };
  }, [setSelectedGroupIdByActivities, selection.selectedActivity]);

  useEffect(() => {
    if (sessionId && mode === 'preview') {
      findRelevantUser(sessionId, selection.selectedActivity, selectedGroupId)
        .then((activeUserId) => {
          if (activeUserId) {
            setImpersonatedUserId(activeUserId);
          } else {
            setImpersonatedUserId(userId);
          }
        })
        .catch((e) => console.error(e));
    } else {
      setImpersonatedUserId(userId);
    }
  }, [userId, mode, sessionId, selection.selectedActivity, selectedGroupId]);

  const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);
  const [copyLinlState, setCopyLinkState] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [wasGroupModalInitialyDisplayed, setWasGroupModalInitialyDisplayed] =
    useState(false);
  const [confirmLiveModal, setConfirmLiveModal] = useState(false);

  useEffect(() => {
    if (copyLinlState) {
      setTimeout(() => {
        setCopyLinkState(false);
      }, 2000);
    }
  }, [copyLinlState]);

  const renderActionsBar = (
    sessionId: string,
    userId: string,
    activity: Activity
  ) => {
    const actionsList = [];
    if (mode && session) {
      if (['editor'].includes(mode)) {
        actionsList.push(
          <ActionsButton
            key="settings"
            label={t('common:settings')}
            size="sm"
            icon={<Wrench />}
            onClick={showGlobalSettings}
          />
        );
      }
      if (['facilitator', 'editor'].includes(mode) && !session.isTemplate) {
        actionsList.push(
          <ActionsButton
            key="sessionLink"
            enabled={copyLinlState}
            label={copyLinlState ? t('sessions:copied') : t('common:link')}
            size="sm"
            icon={copyLinlState ? <Check /> : <LinkIcon />}
            onClick={() => {
              navigator.clipboard.writeText(
                `${domainUrl}/${session.accessCode}`
              );
              setCopyLinkState(true);
            }}
          />
        );
      }
      if (['facilitator'].includes(mode) && !session.isTemplate) {
        actionsList.push(
          <QrCodeActionButton url={`${domainUrl}/${session.accessCode}`} />
        );
      }
      if (role === 'superadmin' && ['editor'].includes(mode)) {
        actionsList.push(
          <ActionsButton
            key="advancedSettings"
            enabled={showAdvancedConfig}
            label={t('common:advanced')}
            size="sm"
            icon={<Adjustments />}
            onClick={() => setShowAdvancedConfig((state) => !state)}
          />
        );
      }
      if (
        activity?.grouping?.mode !== 'All' &&
        ['participant', 'preview'].includes(mode)
      ) {
        actionsList.push(
          <GroupDataAction
            sessionId={sessionId}
            userId={userId}
            activity={activity}
            render={(group) => {
              return (
                <ActionsButton
                  label={
                    group
                      ? t('common:groupX', {
                          groupPrefix: session?.groupPrefix || '',
                          number: group.number || 1,
                        })
                      : t('sessions:myGroup')
                  }
                  size="sm"
                  icon={<UserGroup />}
                  onClick={() => setGroupModalVisible(true)}
                  disabled={mode === 'participant' && !group}
                />
              );
            }}
          />
        );
        actionsList.push(
          <GroupDataAction
            sessionId={sessionId}
            userId={userId}
            activity={activity}
            render={(group) => {
              const assistanceRequested =
                !!group?.assistance?.askedAt && !group?.assistance?.reslovedAt;
              return (
                <ActionsButton
                  key="callFacilitator"
                  enabled={assistanceRequested}
                  label={
                    assistanceRequested
                      ? t('sessions:facilitatorRequested')
                      : t('sessions:callFacilitator')
                  }
                  size="sm"
                  icon={assistanceRequested ? <LightBulb /> : <Hand />}
                  onClick={() => {
                    !assistanceRequested &&
                      group &&
                      callFacilitator(sessionId, activity.name, group.id);
                  }}
                  disabled={mode === 'participant' && !group}
                  colorClassNames={{
                    default: 'text-danger',
                    enabled: 'text-success',
                  }}
                />
              );
            }}
          />
        );
      }
      if (
        ['facilitator', 'preview', 'editor'].includes(mode) &&
        !smoothOnboarding
      ) {
        actionsList.push(
          <ActionsButton
            key="preview"
            enabled={mode === 'preview'}
            label={
              mode === 'preview'
                ? t('sessions:leavePreview')
                : t('common:preview')
            }
            size="sm"
            icon={mode === 'preview' ? <EyeOff /> : <Eye />}
            onClick={() => {
              if (mode === 'preview') {
                setMode(previousMode);
              } else {
                setPreviousMode(mode);
                setMode('preview');
              }
            }}
          />
        );
      }

      if (showConceptionMode && ['facilitator', 'editor'].includes(mode)) {
        actionsList.push(
          <ActionsButton
            key="plan"
            label={t('sessions:peerLearningPlan')}
            size="sm"
            icon={<DocumentText />}
            onClick={switchToDetails}
          />
        );
      }

      if (['facilitator', 'editor'].includes(mode)) {
        actionsList.push(
          <ActionsButton
            key="home"
            label={t('common:SessionHome')}
            size="sm"
            icon={<Home />}
            onClick={switchToHome}
          />
        );
      }

      if (['facilitator', 'editor'].includes(mode)) {
        actionsList.push(
          <ActionsButton
            key="dashboard"
            label={t('common:SessionDashboard')}
            size="sm"
            icon={<PresentationChart />}
            onClick={switchToDashboard}
          />
        );
      }

      const modeDescriptions: { [key in Mode]: string } = {
        editor: t('misc:editor'),
        facilitator: t('misc:facilitator'),
        preview: '',
        participant: t('misc:participant'),
      };
      if (
        showConceptionMode &&
        roleToModes[role].length > 1 &&
        mode !== 'preview' &&
        !session.isTemplate
      ) {
        actionsList.push(
          <div className="w-36" key="modes">
            <Listbox<Mode>
              defaultValue={mode}
              setValue={(value) => {
                if (value !== 'editor') setShowAdvancedConfig(false);
                setMode(value);
              }}
              items={roleToModes[role].map((mode) => ({
                value: mode,
                description: modeDescriptions[mode],
              }))}
            />
          </div>
        );
      }
    }

    return actionsList.length > 0 ? (
      <>{actionsList.map((action) => action)}</>
    ) : undefined;
  };

  const titleActivityDisplayName =
    (activitySettingsName &&
      sessionTemplate?.activities[activitySettingsName].humanName) ||
    t('sessions:Activity_s');

  const enabledActivity = Object.entries(enabledActivities || {}).find(
    ([_, enabled]) => enabled
  )?.[0];

  const selectedActivity = selection.selectedActivity;

  const [
    facilitatorSelectedVideoGroupNumber,
    setFacilitatorSelectedVideoGroupNumber,
  ] = useState<number | null>(null);

  const [facilitatorCurrentVideoActivity, setFacilitatorCurrentVideoActivity] =
    useState<string | null>(null);

  const [facilitatorSelectedVideoGroupId, setFacilitatorSelectedVideoGroupId] =
    useState<string | null>(null);

  const selectVideoGroupId = async (groupId: string) => {
    if (sessionId) {
      const group = await getGroup(sessionId, selectedActivity, groupId);
      setFacilitatorSelectedVideoGroupId(groupId);
      setFacilitatorCurrentVideoActivity(selectedActivity);
      setFacilitatorSelectedVideoGroupNumber(group?.number || null);
    }
  };

  const resetVideoGroupId = () => {
    setFacilitatorSelectedVideoGroupId(null);
    setFacilitatorCurrentVideoActivity(null);
    setFacilitatorSelectedVideoGroupNumber(null);
  };

  useEffect(() => {
    if (enabledActivity && facilitatorCurrentVideoActivity) {
      if (enabledActivity !== facilitatorCurrentVideoActivity) {
        resetVideoGroupId();
      }
    }
  }, [enabledActivity, facilitatorCurrentVideoActivity]);

  const [group, , loadedGroupIdOfSourceEnabledActivity] = useGroup(
    sessionId || 'nowayoufindme',
    userId,
    enabledActivity ? sessionTemplate?.activities[enabledActivity] : undefined
  );

  const meetingName = usePreviousCombinator(
    (oldMeetingName: string | undefined) =>
      computeMeetingNameFrom(
        sessionId,
        group?.number,
        mode || undefined,
        facilitatorSelectedVideoGroupNumber
      )
  );

  const dataReady =
    isLoaded(session, sessionTemplate, enabledActivities) &&
    (mode !== 'participant' ||
      sessionTemplate?.activities?.[enabledActivity || 'unapologetickey']
        ?.grouping.mode !== 'Groups' ||
      loadedGroupIdOfSourceEnabledActivity);

  // console.log('MEETING NAME', dataReady, meetingName);

  // console.log('MEETING LOADED', loadedGroupIdOfSourceEnabledActivity);

  const showGroupModal = useCallback(() => {
    if (
      !wasGroupModalInitialyDisplayed &&
      selectedActivity === enabledActivity
    ) {
      setGroupModalVisible(true);
      setWasGroupModalInitialyDisplayed(true);
    } else if (mode === 'preview') {
      setGroupModalVisible(true);
    }
  }, [wasGroupModalInitialyDisplayed, mode, selectedActivity, enabledActivity]);

  const hideGroupModal = useCallback(() => {
    setGroupModalVisible(false);
  }, []);

  useEffect(() => {
    setWasGroupModalInitialyDisplayed(false);
  }, [enabledActivity]);

  return isLoaded(session, sessionTemplate) &&
    (role === 'organizer' || role === 'superadmin' || isFacilitatorLoaded) &&
    orderedActivities &&
    enabledActivities &&
    activitiesMeta &&
    sessionId &&
    sessionTemplate &&
    session &&
    mode ? (
    <SessionLiveContext.Provider
      value={{
        selectedGroupId: selectedGroupId,
        setSelectedGroupId: setSelectedGroupId,
      }}
    >
      <Layout
        showRightPane={role === 'superadmin' && showAdvancedConfig}
        defaultFullScreen={mode === 'editor' || mode === 'facilitator'}
        showStepsInPriority={mode === 'facilitator'}
        forceFullScreen={
          session.internalVisio || mode === 'editor' || mode === 'facilitator'
        }
        headerContent={
          sessionTemplate.activities[selection.selectedActivity]?.grouping
            ?.mode !== 'All' && ['participant', 'preview'].includes(mode) ? (
            <GroupHeader
              sessionId={sessionId}
              userId={impersonatedUserId}
              activity={sessionTemplate.activities[selection.selectedActivity]}
              groupPrefix={session.groupPrefix}
            />
          ) : undefined
        }
        actionBar={renderActionsBar(
          sessionId,
          impersonatedUserId,
          sessionTemplate.activities[selection.selectedActivity]
        )}
        navBar={
          <MainBar
            title={session.title}
            showLogo={false}
            userType={userType}
            userId={userId}
            userName={userName}
            fullWidth={true}
          />
        }
        secondaryLeftPane={(activitiesListProps) => {
          return (
            <div className="flex flex-col items-center">
              {smoothOnboarding && role !== 'participant' ? (
                <>
                  <div className=" mb-10 flex w-full flex-col space-y-4 pl-4 pr-6">
                    <div>
                      <h4 className="font-semibold">
                        {t('sessions:smoothOnboardingTitle')}
                      </h4>
                      <p>{t('sessions:viewerModeDescription')}</p>
                    </div>
                    <Button
                      icon={<Beaker className="stroke-2" />}
                      text={t('sessions:startTest')}
                      onClick={async () => {
                        try {
                          const accessCodeTest = await createTestSession(
                            sessionId,
                            accessCode,
                            session.title,
                            userId
                          );
                          if (accessCode) {
                            history.push(
                              `/${accessCodeTest}/${selection.selectedActivity}`
                            );
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                    />
                    <Button
                      icon={<PresentationChart className="stroke-2" />}
                      text={t('common:SessionDashboard')}
                      onClick={switchToDashboard}
                    />
                    <Button
                      color="warning"
                      icon={<PlayCircle className="stroke-2" />}
                      text={t('sessions:startLiveAnimation')}
                      onClick={() => setConfirmLiveModal(true)}
                    />
                  </div>
                  <YesNoModal
                    body={t('sessions:confirmStartLiveMessage')}
                    open={confirmLiveModal}
                    onClose={() => setConfirmLiveModal(false)}
                    onClickYes={() => {
                      setSessionState(sessionId, 'live');
                      setMode(previousMode);
                      if (orderedActivities.length > 0) {
                        activateActivity(sessionId, orderedActivities[0].name);
                      }
                    }}
                  />
                </>
              ) : null}
              <ActivitiesList
                userType={userType}
                session={session}
                sessionState={sessionState}
                activities={orderedActivities}
                enabledActivities={enabledActivities}
                activitiesMeta={activitiesMeta}
                mode={mode}
                selectedActivity={selection.selectedActivity}
                sessionPath={selection.sessionPath}
                sessionId={sessionId}
                sessionTemplate={sessionTemplate}
                onActivitySettings={viewActivitySettings}
                productions={productions}
                {...activitiesListProps}
              />
            </div>
          );
        }}
        rightPane={
          mode === 'editor' ? (
            <ParametersPane
              sessionId={sessionId}
              userType={userType}
              activities={sessionTemplate.activities}
              productions={productions}
              session={session}
              sessionTemplate={sessionTemplate}
              selection={selection}
              role={role}
              userId={userId}
            />
          ) : undefined
        }
        videoProps={{
          forceChildrenFullScreen: !session.internalVisio || mode === 'editor',
          childrenThumbnail: (
            <div className="relative flex h-full w-full items-center justify-center p-4">
              <p>{t('video:ClickOnExpandTo')}</p>
            </div>
          ),
          uid: userId,
          meetingName,
          dataReady,
          isFacilitator: mode === 'facilitator',
        }}
        mainPane={
          <SessionRouter
            sessionId={sessionId}
            session={session}
            sessionState={sessionState}
            userId={impersonatedUserId}
            userName={userName}
            userEmail={userEmail}
            role={role}
            mode={mode}
            enabledActivities={enabledActivities}
            activitiesMeta={activitiesMeta}
            productions={productions}
            activities={sessionTemplate.activities}
            orderedActivities={orderedActivities}
            selection={selection}
            groupModalVisible={groupModalVisible}
            showGroupModal={showGroupModal}
            hideGroupModal={hideGroupModal}
            videoGroupId={facilitatorSelectedVideoGroupId}
            selectVideoGroupId={selectVideoGroupId}
            resetVideoGroupId={resetVideoGroupId}
          />
        }
      />
      <Modal
        title={t('sessions:activitySettings', {
          name: titleActivityDisplayName,
        })}
        open={activitySettingsName !== null}
        largeWidth
        body={
          activitySettingsName ? (
            <AdminActivitySettings
              sessionId={sessionId}
              userType={userType}
              activity={sessionTemplate.activities[activitySettingsName]}
              activities={sessionTemplate.activities}
              productions={productions}
            />
          ) : null
        }
        onClose={hideModal}
        footer={[<Button key="submit" text="OK" onClick={hideModal} />]}
      />
    </SessionLiveContext.Provider>
  ) : (
    <LoadingScreen />
  );
};
export default SessionScreen;

export const useSessionLiveContext = () => {
  const context = React.useContext(SessionLiveContext);
  if (context === undefined) {
    throw new Error(
      'useSessionLiveContext must be used within the Main live screen'
    );
  }
  return context;
};
