import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { removeParticipantFromSession } from 'model/sessions';
import { useUsers } from 'model/users';
import { useLocalSearch } from 'services/localSearching';
import { RoleBar, User } from 'types/types';

import UserContentView from './UserContentView';
import LoadingScreen from 'screens/LoadingScreen';

import SearchInput from 'components/SearchInput';
import List from 'components/List';
import Avatar from 'components/Avatar';
import Button from 'components/Button';

import { Post } from 'assets/undraw';
import { ChatAlt, Trash } from 'assets/icons';
import Tooltip from 'components/Tooltip';
import MenuButtons from 'components/MenuButtons';
import ExportsButton from 'blocks/ExportsButton';
import { useSessionParticipantsPost } from 'model/productions';

export type ParticipantsViewProps = {
  sessionId: string;
  role: RoleBar;
  sessionTitle: string;
  userId: string;
};

const ParticipantView = ({
  sessionId,
  role,
  users,
  backUrl,
  sessionTitle,
  userId,
}: ParticipantsViewProps & {
  users: Record<string, User>;
  backUrl: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ userId: string }>();
  const participantId = params?.userId;

  const username = users[participantId]?.name;

  const BackButton = (
    <Button
      design="secondary"
      text={t('common:backToList')}
      onClick={() => {
        history.push(backUrl);
      }}
    />
  );

  return users[participantId] ? (
    <UserContentView
      sessionId={sessionId}
      role={role}
      participantId={participantId}
      userId={userId}
      username={username}
      sessionTitle={sessionTitle}
      extraButtons={[BackButton]}
    />
  ) : (
    <Redirect to={backUrl} />
  );
};

const ParticipantsView = ({
  sessionId,
  sessionTitle,
  role,
  userId,
}: ParticipantsViewProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [searchString, setSearchString] = useState<string>('');

  const [participants, isParticipantsLoaded] =
    useSessionParticipantsPost(sessionId);
  const [users, isUserLoaded] = useUsers(participants || {});

  const searchKeys: (keyof User)[] = useMemo(() => ['email', 'name'], []);
  const filteredParticipants = useLocalSearch(users, searchKeys, searchString);

  const participantsList = useMemo(() => {
    return Object.entries(filteredParticipants);
  }, [filteredParticipants]);

  const postCount = useMemo(() => {
    return Object.values(participants || {}).filter((post) => post !== '')
      .length;
  }, [participants]);

  return isUserLoaded && isParticipantsLoaded ? (
    <div className="flex w-full flex-row flex-wrap lg:flex-nowrap lg:space-x-16">
      <div className="flex-grow">
        <Switch>
          <Route path={`${url}/:userId`}>
            <ParticipantView
              sessionId={sessionId}
              userId={userId}
              role={role}
              sessionTitle={sessionTitle}
              users={users}
              backUrl={url}
            />
          </Route>
          <Route exact path={`${url}`}>
            <div className="mb-4 flex flex-row items-center">
              <h1 className="flex flex-grow flex-row items-center text-3xl font-semibold">
                {t('common:participants')}
                <span className="text-2xl text-gray-400">{` (${
                  Object.values(users || {}).length
                })`}</span>
                <span className="ml-4 flex flex-row items-center space-x-1">
                  <ChatAlt className="h-8 w-8 stroke-2" />
                  <span className="text-2xl text-gray-400">{postCount}</span>
                </span>
              </h1>
              {role === 'organizer' || role === 'superadmin' ? (
                <div className="flex items-center space-x-4">
                  <ExportsButton
                    sessionId={sessionId}
                    showParticipantsExport
                    showAdminExports={role === 'superadmin'}
                  />
                </div>
              ) : null}
            </div>
            <div className="mb-8 w-full">
              <SearchInput
                placeholder={t('common:Search')}
                value={searchString}
                onChange={(value) => setSearchString(value)}
              />
            </div>
            <List
              items={participantsList}
              itemsPerPage={20}
              renderItem={([userId, user]) => (
                <div className="flex flex-row items-center space-x-3 py-4">
                  <Avatar size="lg" name={user.name} />
                  <div className="flex flex-grow flex-col">
                    <div className="flex flex-row items-center space-x-4">
                      <h3 className="text-xl font-semibold">{user.name}</h3>
                    </div>
                    <p className="text-base text-black-soft">{user.email}</p>
                  </div>
                  {participants?.[userId] ? (
                    <Tooltip
                      content={<p className="w-80">{participants?.[userId]}</p>}
                    >
                      <ChatAlt className="h-6 w-6 stroke-2 text-gray-400" />
                    </Tooltip>
                  ) : null}
                  <Button
                    className="shrink-0"
                    design="secondary"
                    text={t('common:seeContent')}
                    size="md"
                    onClick={() => history.push(`${url}/${userId}`)}
                  />
                  {role === 'organizer' || role === 'superadmin' ? (
                    <MenuButtons
                      actions={[
                        {
                          type: 'button',
                          label: t('common:Delete'),
                          severity: 'danger',
                          icon: <Trash className="h-6 w-6 stroke-3/2" />,
                          onClick: () =>
                            removeParticipantFromSession(sessionId, userId),
                        },
                      ]}
                    />
                  ) : null}
                </div>
              )}
              renderEmptyList={() => (
                <>
                  <h2 className="mt-10 px-5 text-center text-xl">
                    {participants && Object.entries(participants).length > 0
                      ? t('sessions:updateSearchParticipant')
                      : t('sessions:noParticipant')}
                  </h2>
                  <Post className="mx-auto mt-10 w-72 fill-current text-primary md:w-80" />
                </>
              )}
            />
          </Route>
        </Switch>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default ParticipantsView;
