import React from 'react';
import { useTranslation } from 'react-i18next';

import Listbox from 'components/Listbox';

import { DocumentText, Filter } from 'assets/icons';
import {
  Configure,
  connectMenu,
  InstantSearch,
} from 'react-instantsearch-core';

import { client, searchDocs } from 'services/searching';
import { algoliaConf as conf } from 'config';
import ClearRefinements from 'frameworks/algoliasearch/ClearRefinements';
import SearchBox from 'frameworks/algoliasearch/SearchBox';
import Pagination from 'frameworks/algoliasearch/Pagination';
import { exportSessionDocs } from 'services/docExport';
import Button from 'components/Button';
import { DocListHits } from './DocList';
import { useUrlSync } from 'frameworks/algoliasearch/hooks';
import _ from 'lodash';
import { useParticipants } from 'model/sessions';
import ExportsButton from 'blocks/ExportsButton';
import { UserType } from 'types/types';
import { isGlobalAdmin } from 'model/users';

const ListBoxMenu = ({ items, refine }: any) => {
  const { t } = useTranslation();
  const selected = items.find((item: any) => item.isRefined);

  const options = [
    {
      value: 'allActivities',
      description: `${t('sessions:AllActivities')} (${_.sumBy(
        items,
        'count'
      )})`,
    },
    ...items.map(({ count, label }: any) => ({
      value: label,
      description: `${label} (${count})`,
    })),
  ];

  return (
    <Listbox
      setValue={(value) => {
        if (value === 'allActivities') {
          return refine();
        }
        refine(value);
      }}
      defaultValue={selected?.label || 'allActivities'}
      placeholder="Sélectionner l'activité"
      items={options}
    />
  );
};

const CustomMenu = connectMenu(ListBoxMenu);

export type ExplorerViewProps = {
  sessionId: string;
  userId: string;
  userType: UserType;
  onDocSelect?: (docId: string) => void;
};

const ExplorerView = ({
  sessionId,
  userId,
  userType,
  onDocSelect,
}: ExplorerViewProps): JSX.Element => {
  const { t } = useTranslation();

  const [participants, isParticipantLoaded] = useParticipants(sessionId);

  const filters = `sessionId:${sessionId}${
    !isGlobalAdmin(userType)
      ? ` AND (NOT visibility:Private OR authorizedIds:${userId})`
      : ''
  }`;

  const [searchState, onSearchStateChange, createUrl] = useUrlSync();

  return (
    <InstantSearch
      searchClient={client}
      indexName={conf.docsIndexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createUrl}
    >
      <Configure filters={filters} />

      <div className="flex w-full flex-row space-x-16">
        <div className="flex-grow space-y-4">
          <div className="mb-4 flex flex-row items-center">
            <h1 className="flex-grow text-3xl font-semibold">
              {t('common:exploreContent')}
            </h1>
            <div className="flex items-center space-x-4">
              <ExportsButton
                sessionId={sessionId}
                showAdminExports={isGlobalAdmin(userType)}
                showDocsExport
              />
            </div>
          </div>
          <>
            <div className="mb-8 w-full">
              <SearchBox />
            </div>
          </>
          <div className="mt-8">
            <Pagination />
          </div>
          <DocListHits onDocClick={onDocSelect} />
          <div className="mt-4">
            <Pagination />
          </div>
        </div>

        <div className="w-80 shrink-0">
          <div className="mb-6 flex flex-row items-center space-x-4">
            <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-soft">
              <Filter className="h-6 w-6" />
            </div>
            <h3 className="text-xl font-semibold">
              {t('common:FilterResults')}
            </h3>
          </div>
          <CustomMenu attribute="type" />
          <div className="mt-8 flex flex-col space-y-2">
            <Button
              size="md"
              design="secondary"
              icon={<DocumentText className="stroke-2" />}
              text={'Exporter les documents filtrés'}
              disabled={!isParticipantLoaded}
              onClick={async () => {
                const result = await searchDocs(
                  searchState.query || '',
                  userId,
                  userType,
                  [sessionId],
                  undefined,
                  searchState.menu
                    ? Object.values(searchState.menu).reduce<
                        Record<string, boolean>
                      >((prev, value) => {
                        if (!!value) prev[value] = true;
                        return prev;
                      }, {})
                    : undefined,
                  undefined,
                  undefined,
                  1000
                );
                const docsIdsToExport = result.hits.map((hit) => hit.objectID);
                await exportSessionDocs({
                  sessionId,
                  participants,
                  fullExport: false,
                  docIdsToExport: docsIdsToExport,
                });
              }}
            />
            <ClearRefinements />
          </div>
        </div>
      </div>
    </InstantSearch>
  );
};

export default ExplorerView;
