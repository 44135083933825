import { database } from 'services/firebase';
import { useRef, useEffect, useState, MutableRefObject, useMemo } from 'react';
import _, { isEqual, clamp, List } from 'lodash';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config.js';

export const URL_REGEXP =
  /(^$)|(^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?=:.,]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$)/;

export const getDatabaseValueAtPath = (path: string): Promise<any> =>
  database
    .ref(path)
    .once('value')
    .then((snap) => snap && snap.val());

export function generateRandomString(length: number, upper?: boolean): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return upper ? result.toUpperCase() : result;
}

export function templateReplace(
  text: string,
  data: Record<string, string>
): string {
  return text.replace(/\{\{ *([^{}}]*) *\}\}/gi, (_str, group) => {
    const key = group.toLowerCase().trim();
    return data[key] || '';
  });
}

export function extractTemplateHint(text: string): string[] {
  const match = text.match(/\{\{ *.* *\}\}/gi) || [];
  return match.map((hint) => hint.toLowerCase().slice(3, -3).trim());
}

export function upperFirst(str: string): string {
  if (str.length === 0) return str;
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function makeKey(string: string): string {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z_0-9]/g, '-')
    .toLowerCase();
}

export function sanitizeKeyContainingDot(string: string): string {
  return string.replace(/\./g, '_dot_');
}

export function newTab(url: string, focus: boolean = false): void {
  const win = window.open(url, '_blank');
  if (focus && win) {
    win.focus();
  }
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePreviousCombinator<T>(f: (oldVal: T | undefined) => T): T {
  const ref = useRef<T>();
  const oldVal = ref.current;
  const newVal = f(oldVal);
  useEffect(() => {
    ref.current = newVal;
  });
  return newVal;
}

export function useDeepEqualMemo<T>(value: T) {
  const ref = useRef<T | undefined>(undefined);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}

export const useMemoCompare = <T>(
  next: T,
  compare: (a: T | undefined, b: T) => boolean
): T => {
  // Ref for storing previous value
  const previousRef = useRef<T>();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous! : next!;
};

export const useTimer = (delayMs: number) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(0);
    const interval = setInterval(() => {
      setTime((time) => time + 1);
    }, delayMs);

    return () => clearInterval(interval);
  }, [delayMs, setTime]);

  return time;
};

export type Interval = {
  min: number;
  max: number;
};

export const overlaps = (
  { min: start1, max: end1 }: Interval,
  { min: start2, max: end2 }: Interval
): boolean =>
  (start2 < start1 && end1 > start1) || (end1 > end2 && end1 > start2);

export const intersection = (
  { min: start1, max: end1 }: Interval,
  { min: start2, max: end2 }: Interval
): Interval => {
  return {
    min: Math.max(start1, start2),
    max: Math.min(end1, end2),
  };
};

export const useIntersection = (
  element: Element | null,
  rootMargin?: string
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    };
  }, [element, rootMargin]);

  return isVisible;
};

export const useProgressTimer = (duration: number | undefined) => {
  const [progress, setProgress] = useState<number | null>(null);
  const time = useTimer(1000);

  useEffect(() => {
    if (duration) {
      setProgress(clamp((time * 1000) / duration, 100));
    }
  }, [duration, time]);

  return duration ? progress : null;
};

export function intersects<T>(
  a: List<T> | null | undefined,
  b: List<T> | null | undefined
) {
  return _.intersection(a, b).length > 0;
}

export const formatTime = (duration: number) => {
  return `${Math.floor(duration / 60)}h${`${Math.floor(
    duration % 60
  )}`.padStart(2, '0')}`;
};

export const useCombinedRefs = <T>(
  refs: MutableRefObject<T | null>[]
): MutableRefObject<T | null> => {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      ref.current = targetRef.current || null;
    });
  }, [refs]);

  return targetRef;
};

export const usePrintingState = () => {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  useEffect(() => {
    const beforePrinting = () => setIsPrinting(true);
    const afterPrinting = () => setIsPrinting(false);

    window.addEventListener('beforeprint', beforePrinting);
    window.addEventListener('afterprint', afterPrinting);

    return () => {
      window.removeEventListener('beforeprint', beforePrinting);
      window.removeEventListener('afterprint', afterPrinting);
    };
  }, []);

  return isPrinting;
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useIsKeyboardOpen = (): boolean => {
  const { width, height } = useWindowDimensions();
  return width < 1024 && height < 512;
};

export const getClosest = <T extends number>(
  target: number,
  array: T[],
  defaultValue: T
): T =>
  array.reduce(function (prev, curr) {
    return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
  }, defaultValue);

type Context = Partial<{ matched: string }>;
type AlternativeRender<T> = (value: T, context: Context) => T;

export type Alternatives<T> = Array<{
  matches: string[];
  item: T;
  render?: AlternativeRender<T>;
}>;

export const replaceText = <T>(
  text: string,
  alternatives: Alternatives<T>,
  render: (
    value: T,
    context: Context,
    alternativeRender?: AlternativeRender<T>
  ) => T,
  aggregate: (values: T[]) => T,
  transform: (value: string) => T
) => {
  const escapedText = text.replaceAll('\\[', '[').replaceAll('\\]', ']');
  const regexpBracket = /\[(.*?)\]/gi;
  const matchedList = escapedText.match(regexpBracket) || [];
  if (matchedList.length > 0) {
    const matched = matchedList[0];
    const alternative = alternatives.find((alt) => {
      return alt.matches.some((match) =>
        matched.toLowerCase().includes(match.toLowerCase())
      );
    });
    const splittedText = escapedText.split(matched);
    const results: T[] = splittedText.reduce<T[]>((prev, current, index) => {
      prev.push(
        replaceText(current, alternatives, render, aggregate, transform)
      );
      if (index !== splittedText.length - 1) {
        if (alternative !== undefined) {
          prev.push(
            render(
              alternative.item,
              {
                matched: matched,
              },
              alternative.render
            )
          );
        } else {
          prev.push(transform(matched));
        }
      }
      return prev;
    }, []);
    return aggregate(results);
  }
  return transform(escapedText);
};

type ActionsList<T> = {
  reset: () => void;
  push: (item: T) => void;
  removeIndex: (index: number) => void;
};
export const useList = <T>(array: T[]): [T[], ActionsList<T>] => {
  const [list, setList] = useState<T[]>(array);

  const actions: ActionsList<T> = useMemo(
    () => ({
      reset: () => setList([]),
      push: (item: T) => {
        setList((state) => [...state, item]);
      },
      removeIndex: (index: number) => {
        setList((state) => state.splice(index, 1));
      },
    }),
    [setList]
  );
  return [list, actions];
};

export const SCREEN_SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];
type ScreenSizeTuple = typeof SCREEN_SIZES;
export type ScreenSize = ScreenSizeTuple[number];

const createUseScreenSize = (screensConf: Record<ScreenSize, string>) => {
  const screenBiggerThan = (breakpoint: ScreenSize): boolean => {
    const value = (screensConf[breakpoint] as string) ?? '999999px';
    const query = window.matchMedia(`(min-width: ${value})`);
    return query.matches;
  };

  const useScreenSize = () => {
    const [size, setSize] = useState<ScreenSize>('xxs');

    useEffect(() => {
      const track = () => {
        let newSize = 'xxs';
        for (const breakpoint of SCREEN_SIZES.slice(1)) {
          if (screenBiggerThan(breakpoint)) {
            newSize = breakpoint;
          } else {
            break;
          }
        }
        if (newSize !== size) {
          setSize(newSize);
        }
      };

      track();

      window.addEventListener('resize', track);
      return () => window.removeEventListener('resize', track);
    });

    return size;
  };

  return useScreenSize;
};

// we use any types because there are plenty of errors after updating tailwind
const config = resolveConfig(tailwindConfig) as any;
export const useScreenSize = createUseScreenSize(config.theme.screens);
export const tailwindTheme = config.theme;

export function generateUUID(): string {
  return (
    new Date().getTime().toString(16) + Math.random().toString(16).substr(2)
  ).substr(2, 16);
}

export const memoizeInDB =
  (path: string, fn: () => Promise<string>) => async (): Promise<string> => {
    const memoizedValue: string | null = (await database.ref(path).get()).val();
    if (!memoizedValue) {
      const val = await fn();
      await database.ref(path).set(val);
      return val;
    }
    return memoizedValue;
  };

export * from './pureUtils';
