import Avatar from 'components/Avatar';
import List from 'components/List';
import SearchInput from 'components/SearchInput';
import Spinner from 'components/Spinner';
import Tag from 'components/Tag';
import {
  demoteAdminAsUser,
  promoteUserAsAdmin,
  removeMemberFromCommunity,
} from 'model/communitiesManagement';
import { isGlobalAdmin, useUsers } from 'model/users';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useLocalSearch } from 'services/localSearching';
import { CommunityRole, User, UserType } from 'types/types';
import MenuButtons from 'components/MenuButtons';
import { Trash, ThumbDown, ThumbUp } from 'assets/icons';
import ExportsButton from 'blocks/CommunityExportsButton';

export type CommunityUsersProps = {
  userId: string;
  userType: UserType;
  communityId: string;
};

const CommunityUsers = ({
  communityId,
  userId,
  userType,
}: CommunityUsersProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');

  useFirebaseConnect([
    `usersOfCommunities/${communityId}`,
    `adminsOfCommunities/${communityId}`,
    `nbOfAttendances/${communityId}`,
  ]);

  const usersOfCommunities: Record<string, CommunityRole> | null = useSelector(
    (state: any) => state.firebase.data.usersOfCommunities?.[communityId]
  );

  const adminsOfCommunities: Record<string, CommunityRole> | null = useSelector(
    (state: any) => state.firebase.data.adminsOfCommunities?.[communityId]
  );

  const nbOfAttendances: Record<string, number> | null = useSelector(
    (state: any) => state.firebase.data.nbOfAttendances?.[communityId]
  );

  const [users, usersLoaded] = useUsers({
    ...usersOfCommunities,
  });

  const [admins, adminsLoaded] = useUsers({
    ...adminsOfCommunities,
  });

  const searchKeys: (keyof User)[] = useMemo(() => ['email', 'name'], []);

  const filteredUsers = useLocalSearch(users, searchKeys, searchString);
  const filteredAdmins = useLocalSearch(admins, searchKeys, searchString);

  const filteredMembersAsArray: [string, { isAdmin?: true } & User][] =
    useMemo(() => {
      return [
        ...Object.entries(filteredAdmins).map(
          ([userId, user]): [string, { isAdmin?: true } & User] => [
            userId,
            {
              ...user,
              isAdmin: true,
            },
          ]
        ),
        ...Object.entries(filteredUsers),
      ];
    }, [filteredAdmins, filteredUsers]);

  const isCommunityAdmin = !!adminsOfCommunities?.[userId];

  return (
    <div className="flex w-full flex-row flex-wrap lg:flex-nowrap lg:space-x-16">
      <div className="flex-grow">
        <div className="mb-4 flex flex-row items-center">
          <h1 className="flex-grow text-3xl font-semibold">
            {t('communities:Members')}
          </h1>
          {isCommunityAdmin || isGlobalAdmin(userType) ? (
            <div className="flex items-center space-x-4">
              <ExportsButton communityId={communityId} />
            </div>
          ) : null}
        </div>
        <div className="mb-8 w-full">
          <SearchInput
            placeholder={t('common:Search')}
            value={searchString}
            onChange={(value) => setSearchString(value)}
          />
        </div>
        {isLoaded(usersOfCommunities, adminsOfCommunities, nbOfAttendances) &&
        usersLoaded &&
        adminsLoaded ? (
          <List
            items={filteredMembersAsArray}
            itemsPerPage={20}
            renderItem={([userId, user]) => (
              <div className="flex flex-row items-center py-4">
                <Avatar size="lg" name={user.name} />
                <div className="ml-3 flex flex-grow flex-col">
                  <div className="flex flex-row items-center space-x-4">
                    <h3 className="text-xl font-semibold">
                      {user.name}{' '}
                      {nbOfAttendances?.[userId] && (
                        <span className="text-sm text-black-soft font-normal">
                          (
                          {t('communities:NbOfAttendances', {
                            count: nbOfAttendances?.[userId],
                          })}
                          )
                        </span>
                      )}
                    </h3>
                    {user.isAdmin && (
                      <Tag text={t('communities:admin')} color="primary" />
                    )}
                  </div>
                  <p className="text-base text-black-soft">{user.email}</p>
                </div>
                {isCommunityAdmin || isGlobalAdmin(userType) ? (
                  <UsersActionList
                    communityId={communityId}
                    userId={userId}
                    isAdmin={!!user.isAdmin}
                  />
                ) : null}
              </div>
            )}
          />
        ) : (
          <div className="flex h-32 w-full items-center justify-center">
            <Spinner className="h-12 w-12 text-primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityUsers;

export type MenuListProps = {
  userName: string;
  userId: string;
  userType: string;
  className: string;
};

type UsersActionListProps = {
  communityId: string;
  userId: string;
  isAdmin: boolean;
};

const UsersActionList = ({
  communityId,
  userId,
  isAdmin,
}: UsersActionListProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <MenuButtons
      size="base"
      actions={[
        {
          type: 'button',
          icon: isAdmin ? (
            <ThumbDown className="h-6 w-6 stroke-3/2" />
          ) : (
            <ThumbUp className="h-6 w-6 stroke-3/2" />
          ),
          label: isAdmin
            ? t('communities:DemoteFromAdmin')
            : t('communities:PromoteToAdmin'),
          onClick: () =>
            isAdmin
              ? demoteAdminAsUser(userId, communityId)
              : promoteUserAsAdmin(userId, communityId),
        },
        {
          type: 'button',
          icon: <Trash className="h-6 w-6 stroke-3/2" />,
          label: t('communities:RemoveFromCommunity'),
          onClick: () => removeMemberFromCommunity(communityId, userId),
          severity: 'danger',
        },
      ]}
    />
  );
};
