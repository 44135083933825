import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _, { isString } from 'lodash';

import MenuButtons, { Action } from 'components/MenuButtons';
import Button from 'components/Button';

import {
  exportAllUsersProduction,
  exportUsers,
  exportFeedbacks,
} from 'services/export';
import {
  exportDocAfterAIFullSynthesis,
  exportSessionDocs,
} from 'services/docExport';
import { useParticipants } from 'model/sessions';
import { useTopics } from 'model/productions';

import { ChevronUp, DocumentText, Duplicate, Table } from 'assets/icons';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import MDReactComponent from 'componentsOld/MDReactComponent';

export type ExportsButtonProps = {
  sessionId: string;
  showAdminExports: boolean;
  showParticipantsExport?: boolean;
  showFeedbackExport?: boolean;
  showDocsExport?: boolean;
  secondary?: boolean;
};

type ExportState = 'exporting' | 'error' | 'null' | 'answer';

const ExportsButton = ({
  sessionId,
  showAdminExports,
  showParticipantsExport,
  showFeedbackExport,
  showDocsExport,
  secondary = false,
}: ExportsButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [participants, isParticipantLoaded] = useParticipants(sessionId);
  const [topics] = useTopics(sessionId);
  const actions: Action[] = [];
  const [exportState, setExportState] = useState<ExportState>('null');
  const [answer, setAnswer] = useState<string | null>(null);

  const withExportModal = async (
    fn: () => Promise<void | string>
  ): Promise<void> => {
    setExportState('exporting');
    try {
      const answer = await fn();
      if (isString(answer)) {
        setAnswer(answer);
        setExportState('answer');
      } else {
        setExportState('null');
      }
    } catch (e) {
      console.error(e);
      setExportState('error');
    }
  };

  const contentRef = useRef<HTMLDivElement>(null);

  if (showDocsExport && isParticipantLoaded) {
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() =>
          exportSessionDocs({
            sessionId,
            participants,
            fullExport: true,
          })
        );
      },
      label: t('sessions:exportAllDocuments'),
      icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
    });

    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() =>
          exportSessionDocs({
            sessionId,
            participants,
            fullExport: true,
            useAI: true,
          })
        );
      },
      label: t('sessions:exportAllDocumentsWithAI'),
      icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
    });

    if (showAdminExports) {
      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportDocAfterAIFullSynthesis({
              sessionId,
              participants,
              synthesisType: 'global',
            })
          );
        },
        label: t('sessions:exportGlobalSynthesis'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });

      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportDocAfterAIFullSynthesis({
              sessionId,
              participants,
              synthesisType: 'inspirations',
            })
          );
        },
        label: t('sessions:exportInspirationsSynthesis'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });
    }

    if (_.size(topics) > 0) {
      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportSessionDocs({
              sessionId,
              participants,
              fullExport: true,
              exportType: 'byTopics',
            })
          );
        },
        label: t('sessions:exportThematicSynthsesis'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });

      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportSessionDocs({
              sessionId,
              participants,
              fullExport: true,
              exportType: 'byTopics',
              useAI: true,
            })
          );
        },
        label: t('sessions:exportThematicSynthsesisWithIA'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });
    }
  }

  if (showFeedbackExport) {
    if (showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportFeedbacks(sessionId));
      },
      label: t('sessions:exportParticipantsFeedbacks'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  if (showParticipantsExport) {
    if (showFeedbackExport || showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportAllUsersProduction(sessionId));
      },
      label: t('sessions:exportRawData'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });

    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportUsers(sessionId));
      },
      label: t('sessions:exportTimesheet'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  const modalTitle =
    exportState === 'exporting' ? (
      t('sessions:ExportInProgress')
    ) : exportState === 'error' ? (
      t('sessions:ExportError')
    ) : exportState === 'answer' ? (
      <div className="flex-row justify-between flex items-center">
        {t('sessions:Synthesis')}
        <Button
          text={t('common:Copy')}
          size="sm"
          icon={<Duplicate className="stroke-2" />}
          enableDoneStatus
          onClick={() => {
            if (contentRef.current) {
              const htmlContent = contentRef.current.innerHTML;
              const blob = new Blob([htmlContent], { type: 'text/html' });
              const clipboardItem = new ClipboardItem({ 'text/html': blob });
              navigator.clipboard.write([clipboardItem]);
            }
          }}
        />
      </div>
    ) : null;

  const modalBody =
    exportState === 'exporting' ? (
      <Spinner className="w-16 h-16 text-primary" />
    ) : exportState === 'error' ? (
      <p>{t('sessions:ExportErrorRetryDescription')}</p>
    ) : exportState === 'answer' && answer ? (
      <div ref={contentRef} className="flex flex-col gap-4">
        <div ref={contentRef}>
          <MDReactComponent text={answer} />
        </div>
      </div>
    ) : null;

  return (
    <>
      <Modal
        onClose={() => setExportState('null')}
        open={exportState !== 'null'}
        fullHeight={exportState === 'answer'}
        title={modalTitle}
        body={
          <div className="flex flex-col items-center min-h-[94px]">
            {modalBody}
            {exportState === 'error' ? (
              <Button
                text={t('common:Close')}
                onClick={() => setExportState('null')}
              />
            ) : null}
          </div>
        }
      />
      <MenuButtons
        menuButton={
          <Button
            design={(secondary && 'secondary') || undefined}
            size={secondary ? 'sm' : 'md'}
            disabled={actions.length === 0}
            text={t('sessions:exportData')}
            icon={<ChevronUp className="rotate-180 stroke-2" />}
            iconPlacement="right"
          />
        }
        size="sm"
        verticalGap={false}
        iconPlacement="left"
        actions={actions}
      />
    </>
  );
};

export default ExportsButton;
