import { database as db } from 'services/firebase';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';

export const useConfidentialityList = (userId: string): [string[], boolean] => {
  useFirebaseConnect(userId ? 'confidentialityList' : []);

  const list = useSelector(
    (state: any) => state.firebase.data.confidentialityList
  );

  return [list || [], isLoaded(list)];
};

export const saveConfidentialityList = async (
  confidentialityList: string[]
): Promise<void> => {
  await db.ref('confidentialityList').set(confidentialityList);
};

export const useAISynthesisPrompts = (
  userId: string
): [{ globalPrompt: string; inspirationsPrompt: string }, boolean] => {
  useFirebaseConnect(userId ? 'aiSynthesisPrompts' : []);

  const prompts = useSelector(
    (state: any) => state.firebase.data.aiSynthesisPrompts
  );

  return [
    {
      globalPrompt: prompts?.globalPrompt || '',
      inspirationsPrompt: prompts?.inspirationsPrompt || '',
    },
    isLoaded(prompts),
  ];
};

export const saveAISynthesisPrompts = async (
  globalPrompt: string,
  inspirationsPrompt: string
): Promise<void> => {
  await db.ref('aiSynthesisPrompts').set({
    globalPrompt,
    inspirationsPrompt,
  });
};
