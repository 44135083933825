import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import { Session, UserType, RoleBar, SessionStatusState } from 'types/types';
import { Nullable } from 'types/typesUtils';
import { domainUrl } from 'config';
import { isDevelopper } from 'constants/AppConfig';
import { buildCallToPostEmail } from 'services/mail';
import { getTasks } from '../tasks';
import { useGlobalModal } from 'contexts/GlobalModal';
import { createTestSession } from 'model/sessions';

import MainBar from 'components/MainBar';
import Banner from 'components/Banner';
import Tabs, { TabInfo } from 'components/Tabs';
import Button from 'components/Button';
import MenuButtons, { Action } from 'components/MenuButtons';
import Tag from 'components/Tag';

import OrganizersDescription from './blocks/OrganizersDescription';
import ExportsButton from 'blocks/ExportsButton';
import SessionPlanView from './views/SessionPlanView';
import UserContentView from './views/UserContentView';
import ParticipantsView from './views/ParticipantsView';
import AllContentView from './views/AllContentView';
import FeedbackView from './views/FeedbackView';
import DebriefView from './views/DebriefView/DebriefView';
import SettingsView from './views/SettingsView';
import FollowUpView from './views/FollowUpView';
import FollowUpViewLite from './views/FollowUpViewLite';
import ConceptionView from './views/ConceptionView';

import getSteps from '../tasks';

import {
  Link,
  CogLight,
  Sparkles,
  ListOl,
  Duplicate,
  Mail,
  Template,
  Cog,
  ShieldExclamation,
  Support,
  Beaker,
} from 'assets/icons';
import {
  setSessionState,
  setSessionFollowUp,
  setSessionExpertMode,
  setSessionInAssistance,
} from 'model/sessions';
import Alert from 'components/Alert';
import { isGlobalAdmin } from 'model/users';

export type SessionHomeScreenProps = {
  userId: string;
  userType: UserType;
  userName: string;
  sessionId: string;
  session: Session;
  role: RoleBar;
  switchToSession: (mode?: 'editor' | 'facilitator') => void;
  sessionState: Nullable<SessionStatusState>;
  accessCode: string;
  sessionFollowUp: boolean;
  communityName?: string;
  showGlobalSettings: () => void;
  sessionExpertMode: boolean;
  sessionInAssistance: boolean;
  sessionInTest: boolean;
};

const SessionHomeScreen = ({
  userId,
  userType,
  userName,
  sessionId,
  session,
  role,
  switchToSession,
  sessionState,
  accessCode,
  sessionFollowUp,
  communityName,
  showGlobalSettings,
  sessionExpertMode,
  sessionInAssistance,
  sessionInTest,
}: SessionHomeScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { show: showCreateSession } = useGlobalModal('createSession');

  const handleCopyEmailInvitation = async () => {
    const textToCopy = await buildCallToPostEmail(sessionId);
    copy(textToCopy, {
      format: 'text/html',
    });
  };

  const scrollableDiv = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTo(0, 0);
    }
  };

  const sessionStateUsed: SessionStatusState = sessionState || 'kickoff';

  const getStatusDescription = useCallback(
    (status: SessionStatusState): string => {
      switch (status) {
        case 'kickoff':
          return t('sessions:SessionKickoff');
        case 'pre_conception':
          return t('sessions:SessionPreconception');
        case 'not_started':
          return t('sessions:SessionNotStarted');
        case 'assign_role':
          return t('sessions:AssignRoles');
        case 'live':
          return t('sessions:SessionOnGoing');
        case 'finished':
          return t('sessions:SessionFinished');
      }
    },
    [t]
  );

  const steps = useMemo(() => {
    return getSteps(t);
  }, [t]);

  const taskListLite = useMemo(() => {
    return getTasks(t, [
      'invitationEmail',
      'recallEmail',
      'remindEmail',
      'facilitationTraining',
      'technicalReview',
    ]);
  }, [t]);

  const currentStep = useMemo(() => {
    const stepsArray = Object.values(steps || {});
    if (stepsArray.length === 1) return stepsArray[0];
    return steps[sessionStateUsed];
  }, [sessionStateUsed, steps]);

  const tabs: { info: TabInfo; component: JSX.Element }[] = useMemo(() => {
    const newTabs: { info: TabInfo; component: JSX.Element }[] = [];

    if (role === 'participant') {
      newTabs.push({
        info: {
          key: 'productions',
          label: t('sessions:myProductions'),
        },
        component: (
          <UserContentView
            sessionId={sessionId}
            role={role}
            participantId={userId}
            userId={userId}
            username={userName}
            sessionTitle={session.title}
          />
        ),
      });

      newTabs.push({
        info: {
          key: 'participants',
          label: t('common:participants'),
        },
        component: (
          <ParticipantsView
            userId={userId}
            sessionId={sessionId}
            role={role}
            sessionTitle={session.title}
          />
        ),
      });

      newTabs.push({
        info: {
          key: 'feedback',
          label: t('common:feedback'),
        },
        component: (
          <FeedbackView
            sessionId={sessionId}
            role={role}
            anonymous={true}
            showExportButton={false}
          />
        ),
      });

      newTabs.push({
        info: {
          key: 'debrief',
          label: t('common:debrief'),
        },
        component: (
          <DebriefView
            sessionId={sessionId}
            userId={userId}
            margin="max-w-5xl"
          />
        ),
      });

      newTabs.push({
        info: {
          key: 'explore',
          label: t('sessions:allContents'),
        },
        component: (
          <AllContentView
            sessionId={sessionId}
            userId={userId}
            userType={userType}
          />
        ),
      });
    } else if (currentStep) {
      if (!sessionExpertMode) {
        newTabs.push({
          info: {
            key: `conception`,
            label: t('common:Conception'),
          },
          component: (
            <ConceptionView
              sessionId={sessionId}
              userId={userId}
              userType={userType}
              enableExpertMode={() => setSessionExpertMode(sessionId, true)}
              rebuildSessionPlanAllowed={
                !['live', 'finished'].includes(sessionStateUsed) &&
                !sessionInAssistance
              }
            />
          ),
        });
      }
      if (currentStep.tasks.length > 0 && sessionFollowUp) {
        newTabs.push({
          info: {
            key: `follow_up`,
            label: t('common:followUp'),
          },
          component: sessionExpertMode ? (
            <FollowUpView
              userId={userId}
              userType={userType}
              sessionId={sessionId}
              sessionState={sessionStateUsed}
              currentStep={currentStep}
              steps={steps}
              accessCode={accessCode}
            />
          ) : (
            <FollowUpViewLite
              userId={userId}
              userType={userType}
              sessionId={sessionId}
              tasks={taskListLite}
              accessCode={accessCode}
            />
          ),
        });
      }
      if (sessionExpertMode) {
        newTabs.push({
          info: {
            key: 'settings',
            label: t('common:settings'),
          },
          component: <SettingsView sessionId={sessionId} userId={userId} />,
        });
      }

      if (
        !session.isTemplate
        // !['kickoff', 'pre_conception'].includes(sessionStateUsed) ||
        // !sessionFollowUp
      ) {
        newTabs.push({
          info: {
            key: 'participants',
            label: t('common:participants'),
          },
          component: (
            <ParticipantsView
              userId={userId}
              sessionId={sessionId}
              role={role}
              sessionTitle={session.title}
            />
          ),
        });
      }

      if (
        (!['kickoff', 'pre_conception', 'not_started'].includes(
          sessionStateUsed
        ) ||
          !sessionFollowUp) &&
        !session.isTemplate
      ) {
        newTabs.push({
          info: {
            key: 'feedback',
            label: t('common:feedback'),
          },
          component: (
            <FeedbackView sessionId={sessionId} role={role} anonymous={false} />
          ),
        });
        newTabs.push({
          info: {
            key: 'debrief',
            label: t('common:debrief'),
          },
          component: (
            <DebriefView
              sessionId={sessionId}
              userId={userId}
              readOnly={false}
              margin="max-w-5xl"
            />
          ),
        });
        newTabs.push({
          info: {
            key: 'explore',
            label: t('sessions:allContents'),
          },
          component: (
            <AllContentView
              sessionId={sessionId}
              userId={userId}
              userType={userType}
            />
          ),
        });
      }

      if (sessionExpertMode) {
        newTabs.push({
          info: {
            key: 'session_plan',
            label: t('sessions:peerLearningPlan'),
          },
          component: (
            <SessionPlanView
              sessionId={sessionId}
              role={role}
              title={t('sessions:peerLearningPlan')}
            />
          ),
        });
      }
    }

    return newTabs;
  }, [
    role,
    sessionId,
    accessCode,
    t,
    userName,
    session.title,
    sessionFollowUp,
    userId,
    userType,
    sessionStateUsed,
    currentStep,
    steps,
    session.isTemplate,
    taskListLite,
    sessionExpertMode,
    sessionInAssistance,
  ]);

  const parts = pathname.split('/');

  const selected: string | undefined = tabs.find(
    (tab) => tab.info.key === parts[3]
  )?.info.key;

  useEffect(() => {
    if (selected) {
      scrollToTop();
    }
  }, [selected]);

  const showDataAlert =
    sessionState === 'finished' &&
    selected === 'follow_up' &&
    role !== 'participant';

  const menuActions: Action[] = [];

  if (sessionExpertMode) {
    menuActions.push({
      type: 'button',
      label: t('sessions:designSession'),
      icon: <CogLight className="h-6 w-6 stroke-3/2" />,
      onClick: () => switchToSession('editor'),
    });
  }

  if (!session.isTemplate) {
    if (sessionExpertMode) {
      menuActions.push({
        type: 'button',
        label: t('sessions:facilitateSession'),
        icon: <Sparkles className="h-6 w-6 stroke-3/2" />,
        onClick: () => switchToSession('facilitator'),
      });
    }
    menuActions.push({
      type: 'button',
      label: t('common:CopyLink'),
      icon: <Link className="h-6 w-6 fill-current stroke-current stroke-2" />,
      onClick: () => {
        navigator.clipboard.writeText(`${domainUrl}/${session.accessCode}`);
      },
    });
    menuActions.push({
      type: 'button',
      label: t('common:CopyInvitationEmail'),
      icon: <Mail className="h-6 w-6 stroke-current stroke-2" />,
      onClick: handleCopyEmailInvitation,
    });
  }

  if (role === 'organizer' || role === 'superadmin') {
    menuActions.push({
      type: 'divider',
    });
    menuActions.push({
      type: 'button',
      label: t('sessions:duplicate'),
      icon: <Duplicate className="h-6 w-6 stroke-3/2" />,
      onClick: async () => {
        showCreateSession({
          modalTitle: t('sessions:duplicateSessionTitle'),
          title: t('common:duplicatedTag', { text: session.title }),
          submitLabel: t('common:Duplicate'),
          duplicatedSessionId: sessionId,
        });
      },
    });

    if (!sessionInTest) {
      menuActions.push({
        type: 'button',
        label: t('sessions:startTest'),
        icon: <Beaker className="h-6 w-6 stroke-2" />,
        onClick: async () => {
          try {
            const accessCodeTest = await createTestSession(
              sessionId,
              accessCode,
              session.title,
              userId
            );
            if (accessCode) {
              history.push(`/${accessCodeTest}`);
            }
          } catch (error) {
            console.error(error);
          }
        },
      });
    }

    if (role === 'superadmin') {
      menuActions.push({
        type: 'button',
        label: t('sessions:makeTemplate'),
        icon: <Template className="h-6 w-6 stroke-3/2" />,
        onClick: () => history.push(`/template/${accessCode}`),
      });
    }

    if (!sessionExpertMode && isGlobalAdmin(userType)) {
      menuActions.push({
        type: 'divider',
      });
      menuActions.push({
        type: 'button',
        label: sessionInAssistance
          ? t('sessions:DisableInAssistanceMode')
          : t('sessions:EnableInAssistanceMode'),
        icon: <Support className="h-6 w-6 stroke-current stroke-2" />,
        onClick: () => {
          setSessionInAssistance(sessionId, !sessionInAssistance);
        },
        severity: 'warning',
      });
    }
  }

  if (isDevelopper()) {
    menuActions.push({
      type: 'divider',
    });
    menuActions.push({
      type: 'button',
      label: sessionFollowUp
        ? t('sessions:DisableFollowUp')
        : t('sessions:EnableFollowUp'),
      icon: <ListOl className="h-6 w-6 fill-current stroke-current stroke-2" />,
      onClick: () => {
        setSessionFollowUp(sessionId, !sessionFollowUp);
      },
      severity: 'danger',
    });
    menuActions.push({
      type: 'button',
      label: sessionExpertMode
        ? t('sessions:DisableExpertMode')
        : t('sessions:EnableExpertMode'),
      icon: <ShieldExclamation className="h-6 w-6 stroke-current stroke-2" />,
      onClick: () => {
        setSessionExpertMode(sessionId, !sessionExpertMode);
      },
      severity: 'danger',
    });
  }

  return (
    <div className="print-color-exact flex h-screen w-full flex-col bg-white text-left print:h-auto">
      <MainBar
        userId={userId}
        userType={userType}
        userName={userName}
        customWidthClass="max-w-5xl px-2"
      />
      <div
        ref={scrollableDiv}
        className="relative flex flex-grow scroll-pt-2 flex-col overflow-y-auto print:overflow-y-hidden"
      >
        <Banner className="shrink-0">
          <div className="w-full">
            <div className="mx-auto flex max-w-5xl flex-row justify-between space-x-4 px-4">
              <div className="z-70  flex cursor-text flex-col">
                <h1 className="text-4xl font-semibold text-white line-clamp-2">
                  {session.title}
                </h1>
                {!session.isTemplate ? (
                  <>
                    <div className="mb-1 flex flex-row space-x-1 text-base text-primary-soft">
                      <OrganizersDescription sessionId={sessionId} />
                      {communityName ? (
                        <>
                          <p>{t('common:for')}</p>
                          <p
                            className="z-20 cursor-pointer font-semibold underline underline-offset-1"
                            onClick={() =>
                              history.push(`/community/${session.communityId}`)
                            }
                          >
                            {communityName}
                          </p>
                        </>
                      ) : null}
                    </div>
                    <div className="mb-1 flex flex-row flex-wrap items-center space-x-4">
                      <p className="text-base font-semibold uppercase text-white line-clamp-1">
                        {`${moment(session!.scheduledAt[0]).format(
                          t('misc:dateFormat')
                        )} ${t('misc:to')} ${moment(
                          session!.scheduledAt[0]
                        ).format(t('misc:timeFormat'))}`}
                      </p>
                      {sessionStateUsed ? (
                        <div className="flex space-x-4 print:hidden">
                          {isDevelopper() ? (
                            <>
                              <Tag
                                className="shrink-0"
                                text={getStatusDescription(sessionStateUsed)}
                                color="gray"
                              />
                              <MenuButtons
                                size="sm"
                                actions={(
                                  Object.keys(steps) as SessionStatusState[]
                                ).map((key) => {
                                  return {
                                    type: 'button',
                                    label: getStatusDescription(key),
                                    onClick: () =>
                                      setSessionState(sessionId, key),
                                  };
                                })}
                              />
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {role !== 'participant' ? (
                      <div className="mt-2 mb-1 flex flex-row flex-wrap items-center space-x-2">
                        <Button
                          icon={<Mail className="stroke-2" />}
                          text={t('common:CopyInvitationEmail')}
                          design="secondary"
                          size="sm"
                          enableDoneStatus
                          onClick={handleCopyEmailInvitation}
                        />
                        <ExportsButton
                          sessionId={sessionId}
                          showAdminExports={role === 'superadmin'}
                          showParticipantsExport
                          showFeedbackExport
                          showDocsExport
                          secondary
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              {!sessionExpertMode && role !== 'participant' ? (
                <div
                  className="group z-10 mt-1 flex h-min cursor-pointer flex-row space-x-1 text-white hover:text-primary-soft"
                  onClick={showGlobalSettings}
                >
                  <Cog className="h-6 w-6 " />
                  <p className="border-b border-white font-semibold group-hover:border-primary-soft ">
                    {t('common:settings')}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </Banner>
        <div className=" sticky top-0 z-60 border-b border-surfaces-divider bg-white print:hidden">
          <div className="mx-auto flex max-w-5xl flex-row items-center justify-between px-4">
            <div className="flex min-w-0 flex-grow">
              <Tabs
                controlled
                tabs={tabs.map(({ info }: { info: TabInfo }) => info)}
                selected={selected}
                onChange={(key) => history.push(`${url}/${key}`)}
              />
            </div>
            {role !== 'participant' /*&&
            sessionStateUsed &&
            !['kickoff', 'pre_conception'].includes(sessionStateUsed)*/ ? (
              <div className="my-1 flex shrink-0 flex-row items-center space-x-3">
                {session.isTemplate ? (
                  <Button
                    text={t('sessions:editTemplate')}
                    onClick={() => switchToSession('editor')}
                  />
                ) : (
                  <Button
                    text={t('sessions:GoToSession')}
                    onClick={() =>
                      sessionState !== 'live' &&
                      (sessionExpertMode || sessionInAssistance)
                        ? switchToSession('editor')
                        : switchToSession('facilitator')
                    }
                  />
                )}
                {!session.isTemplate ? (
                  <MenuButtons actions={menuActions} />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {showDataAlert ? (
          <div className="mx-auto flex max-w-5xl flex-row items-center justify-between p-2 pt-6">
            <Alert
              className="w-full"
              title={t('sessions:congratulationSessionfinished')}
              message={t('sessions:capitalizeOnData')}
              rounded
              actionButton={
                <ExportsButton
                  sessionId={sessionId}
                  showAdminExports={role === 'superadmin'}
                  showParticipantsExport
                  showFeedbackExport
                  showDocsExport
                />
              }
            />
          </div>
        ) : null}
        <div className="flex-grow">
          <div className="mx-12 px-4 pt-6">
            <Switch>
              {tabs.map((tab) => {
                return (
                  <Route key={tab.info.key} path={`${url}/${tab.info.key}`}>
                    {tab.component}
                  </Route>
                );
              })}
              <Route path={`${url}/`}>
                <Redirect to={`${url}/${tabs[0].info.key}`} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionHomeScreen;
