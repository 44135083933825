export const exportAsCsv = (rows: string[][], title: string) => {
  // using universal BOM for excel
  // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue/42466254
  let csvContent = '\uFEFF';

  rows.forEach(function (rowArray) {
    let row = rowArray
      .map((el) => {
        return !el ? '' : `"${el.replace(/"/g, '""')}"`;
      })
      .join(';');
    csvContent += row + '\r\n';
  });

  var encodedUri = URL.createObjectURL(
    new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
  );

  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', title);
  document.body.appendChild(link); // Required for FF

  link.click();
};
