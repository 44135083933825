import {
  Packer,
  Document,
  ISectionOptions,
  Header,
  Paragraph,
  Footer,
  TextRun,
  ShadingType,
  //TableOfContents,
} from 'docx';

import { saveAs } from 'file-saver';
import {
  Activities,
  Activity,
  Production,
  Session,
  DocWithMeta,
  SessionTemplate,
  DocMeta,
  ActivityData,
  ProductionMultiplicity,
  SessionLanguage,
} from 'types/types';
import {
  getDocs,
  getDoc,
  buildAuthorsLine,
  removeEmptyText,
  removeInstructions,
  textualizeDoc,
  createDisplayNameMap,
  DisplayNameMap,
  getContextFromHints,
} from 'model/docManagement';
import { fetchUserName } from 'model/users';
import {
  serializeFragments,
  DocFragment,
  serialize,
} from 'frameworks/plate/plugins/docx-serializer';
import _ from 'lodash';
import { fetchData } from 'services/firebase';
import i18n from 'services/i18n';
import { TNode } from '@udecode/plate-core';
import { Users } from 'types/types';
import docXStyles from 'frameworks/docx/styles';
import { TrueSet } from 'types/types';
import moment from 'moment';
import { sequencialForEach } from 'utils/pureUtils';
import { SessionExportType } from 'types/ui';
import { getPostsMeta, getTopics } from 'model/productions';
import { correctActivityDocument, getPostSummary } from 'model/summaries';
import { promptAIForSynthesis } from 'services/tln';
import { templateReplace } from 'utils/utils';

export const saveDoc = async (name: string, document: Document) => {
  const blob = await Packer.toBlob(document);

  return saveAs(blob, `${name}.docx`);
};

export const sectionsToDoc = (sections: ISectionOptions[]) => {
  return new Document({
    features: {
      updateFields: true,
    },
    sections,
    externalStyles: docXStyles,
  });
};

export const getDocIdsByUsers = async (
  sessionId: string,
  activityName: string,
  productionName: string,
  multiplicity: ProductionMultiplicity | undefined,
  userIds: string[]
): Promise<string[]> => {
  const docIds: string[] = [];

  const docsIdsTree = await fetchData<Record<string, any>>(
    `sessionsNextData/${sessionId}/activities/${activityName}/productions/${productionName}`
  );

  const activity = await fetchData<Activity>(
    `sessionsNextTemplates/${sessionId}/activities/${activityName}`
  );

  const activityData = await fetchData<ActivityData>(
    `sessionsNextData/${sessionId}/activities/${activityName}`
  );

  const production: Production | undefined =
    activity?.productions?.[productionName];

  userIds.forEach((userId) => {
    let doc: TrueSet | null = null;

    let path = '';
    if (activity?.grouping?.mode === 'Groups' && production) {
      const groupId = activityData?.grouping?.groupOfUser?.[userId];
      if (groupId && production.mode === 'ByGroup') {
        path += `${groupId}`;
      }
      if (production.multiplicity === 'Each' || production.mode === 'ByUser') {
        path += path ? `.${userId}` : `${userId}`;
      }
      if (path) {
        doc = _.get(docsIdsTree, path);
      }
    }

    if (doc) {
      docIds.push(Object.keys(doc)[0]);
    }
  });
  return docIds;
};

export const productionToDoc = async (
  sessionId: string,
  sessionLanguage: SessionLanguage,
  activityName: string,
  production: Production,
  displayNameMap: DisplayNameMap,
  fullExport: boolean,
  docIdsToExport?: string[],
  userIds?: string[],
  smallerTitle?: boolean,
  useAI: boolean = false
): Promise<DocFragment[]> => {
  const titles: Record<string, string> = await fetchData<
    Record<string, string>
  >(
    `sessionsNextData/${sessionId}/activities/augment_post/productions/title`,
    {}
  );

  const posts: Record<string, string> = await fetchData<Record<string, string>>(
    `sessionsNextData/${sessionId}/activities/post/productions/post`,
    {}
  );

  if (production.type === 'document') {
    let docsIds = docIdsToExport;
    if (userIds) {
      const docIdsToExportByUserId = await getDocIdsByUsers(
        sessionId,
        activityName,
        production.name,
        production.multiplicity,
        userIds
      );
      docsIds = docIdsToExport
        ? _.intersection(docIdsToExport, docIdsToExportByUserId)
        : docIdsToExportByUserId;
    }

    const documents = await getDocs(
      sessionId,
      activityName,
      production.name,
      docsIds
    );
    const docsExports = await Promise.all(
      documents.map(async (doc) => {
        const authorIdsArray = doc.authorsIds
          ? Object.keys(doc.authorsIds)
          : [];
        const authorText = buildAuthorsLine(authorIdsArray, displayNameMap);
        let cleanDocContent = removeInstructions(
          removeEmptyText(doc.content as TNode[])
        );

        if (useAI) {
          try {
            const summaryText = await correctActivityDocument(
              sessionId,
              sessionLanguage,
              doc.id,
              textualizeDoc(cleanDocContent)
            );

            cleanDocContent = summaryText.split('\n').map((line) => ({
              type: 'p',
              children: [{ text: line }],
            }));
          } catch (error) {
            console.log(error);
          }
        }

        return [
          ...(authorText
            ? [
                new Paragraph({
                  text: `${
                    activityName === 'share' && titles[authorIdsArray[0]]
                      ? `${titles[authorIdsArray[0]]} - `
                      : ''
                  }${authorText}`,
                  style: smallerTitle ? 'Titre3' : 'Titre2',
                }),
                ...(activityName === 'share' &&
                useAI &&
                posts[authorIdsArray[0]]
                  ? [
                      new Paragraph({
                        text: `${await getPostSummary(
                          sessionId,
                          'fr',
                          authorIdsArray[0],
                          posts[authorIdsArray[0]],
                          displayNameMap[authorIdsArray[0]] ||
                            i18n.t('common:unknowUser')
                        )}`,
                        style: 'Citation',
                      }),
                    ]
                  : []),
              ]
            : [new Paragraph({ children: [new TextRun('')] })]),
          ...(await serializeFragments(cleanDocContent)),
        ];
      })
    );
    return _.flatten(docsExports);
  } else if (
    fullExport &&
    production.type === 'string' &&
    production.name === 'post'
  ) {
    const productions: Record<string, string> =
      (await fetchData(
        `sessionsNextData/${sessionId}/activities/${activityName}/productions/${production.name}`
      )) || {};

    const postsExports = await Promise.all(
      Object.entries(productions)
        .filter(
          ([userId, production]) =>
            (!userIds || userIds.includes(userId)) &&
            (production || titles[userId])
        )
        .map(async ([userId, production], index) => {
          let content = production;
          if (production && useAI) {
            try {
              content = await getPostSummary(
                sessionId,
                'fr',
                userId,
                production,
                displayNameMap[userId] || i18n.t('common:unknowUser')
              );
            } catch (error) {
              console.error(error);
            }
          }

          return [
            new Paragraph({
              text: `${titles[userId] ? `${titles[userId]} - ` : ''}${
                content ? `«${content}» - ` : ''
              }${displayNameMap[userId] || i18n.t('common:unknowUser')}`,
              style: index % 2 ? 'Citationintense' : 'Citation',
            }),
          ];
        })
    );
    return _.flatten(postsExports);
  } else {
    return [];
  }
};

export const activitiesToDoc = async (
  sessionId: string,
  sessionLanguage: SessionLanguage,
  activities: Activities,
  displayNameMap: DisplayNameMap,
  fullExport: boolean,
  docIdsToExport?: string[],
  useAI: boolean = false
): Promise<DocFragment[]> => {
  const orderedActivities = { ...activities };
  if (orderedActivities['augment_post']) {
    delete orderedActivities['augment_post'];
  }

  const fragments = await _.sortBy(
    Object.values(orderedActivities),
    'index'
  ).reduce(async (prec: Promise<DocFragment[]>, activity: Activity) => {
    const sections = await prec;
    const activitySections: DocFragment[][] = await Promise.all(
      Object.values(activity.productions || {}).map(async (production) => {
        return productionToDoc(
          sessionId,
          sessionLanguage,
          activity.name,
          production,
          displayNameMap,
          fullExport,
          docIdsToExport,
          undefined,
          undefined,
          useAI
        );
      })
    );

    const activityProductions = _.flatten(activitySections);
    if (activityProductions.length > 0) {
      console.log('break before');
      activityProductions.unshift(
        new Paragraph({
          text: activity.humanName || activity.name,
          style: 'Titre1',
        })
      );
    } else if (
      fullExport &&
      !['choose', 'feedback'].includes(activity.name) &&
      // contenus step
      !activity.screens['explore']
    ) {
      activityProductions.push(
        new Paragraph({
          text: activity.humanName || activity.name,
          style: 'Titre1',
        })
      );
      activityProductions.push(
        new Paragraph({
          children: [
            new TextRun({
              text: i18n.t('sessions:noInformationForThisactivity'),
              bold: true,
            }),
          ],
          spacing: {
            after: 200,
          },
        })
      );
    }
    return [...sections, ...activityProductions];
  }, Promise.resolve([]));

  return fragments;
};

export const topicsToDoc = async (
  sessionId: string,
  sessionLanguage: SessionLanguage,
  activities: Activities,
  displayNameMap: DisplayNameMap,
  fullExport: boolean,
  docIdsToExport?: string[],
  useAI: boolean = false
): Promise<DocFragment[]> => {
  const orderedActivities = { ...activities };
  // reoder step to get synthsesis before sharings
  if (orderedActivities['share'] && orderedActivities['collaborate']) {
    orderedActivities['share'].index = activities['collaborate'].index;
    orderedActivities['collaborate'].index = activities['share'].index;
  }

  if (orderedActivities['post']) {
    delete orderedActivities['post'];
  }

  if (orderedActivities['augment_post']) {
    delete orderedActivities['augment_post'];
  }

  const fragments: DocFragment[] = [];
  const topics = await getTopics(sessionId);
  // experimental topic synthsesis only work with first post step named 'post' in order to only add complexity if needed in the future
  const postsMeta = await getPostsMeta(sessionId, 'post');
  const userIdByTopicId = Object.entries(postsMeta).reduce<
    Record<string, string[]>
  >((prev, [userId, postMeta]) => {
    if (postMeta.topic) {
      if (prev[postMeta.topic]) {
        prev[postMeta.topic].push(userId);
      } else {
        prev[postMeta.topic] = [userId];
      }
    }
    return prev;
  }, {});
  await sequencialForEach(Object.entries(topics), async ([topicId, topic]) => {
    const userIds: string[] = userIdByTopicId[topicId] || [];
    const topicsFragments = await _.sortBy(
      Object.values(orderedActivities),
      'index'
    ).reduce(async (prec: Promise<DocFragment[]>, activity: Activity) => {
      const sections = await prec;
      const activitySections: DocFragment[][] = await Promise.all(
        Object.values(activity.productions || {}).map(async (production) => {
          return productionToDoc(
            sessionId,
            sessionLanguage,
            activity.name,
            production,
            displayNameMap,
            fullExport,
            docIdsToExport,
            userIds,
            true,
            useAI
          );
        })
      );
      const activityProductions = _.flatten(activitySections);
      if (activityProductions.length > 0) {
        activityProductions.unshift(
          new Paragraph({
            text: activity.humanName || activity.name,
            style: 'Titre2',
          })
        );
      }
      return [...sections, ...activityProductions];
    }, Promise.resolve([]));
    if (topicsFragments.length > 0) {
      const authorText = buildAuthorsLine(userIds, displayNameMap);
      fragments.push(
        new Paragraph({
          text: `${topic.description} - ${authorText}`,
          style: 'Titre1',
        })
      );
    }
    fragments.push(...topicsFragments);
  });
  return fragments;
};

const exportFeedbacks = async (sessionId: string) => {
  const feedbackFragments = [
    new Paragraph({
      text: 'Feedbacks',
      style: 'Titre1',
    }),
  ];

  const feedbackDescriptions = await fetchData<Activity>(
    `sessionsNextTemplates/${sessionId}/activities/feedback`
  );

  const feedbackData = await fetchData<ActivityData>(
    `sessionsNextData/${sessionId}/activities/feedback`
  );

  const questions =
    feedbackDescriptions?.screens.form.content.template.editableContent;

  const valuableValues = Object.values(
    feedbackData?.productions?.valuable || {}
  ).filter((val) => _.isNumber(val));
  const valuable =
    valuableValues.length > 0 ? _.mean(valuableValues).toFixed(1) : 'N/A';

  const utilityArray = Object.values(feedbackData?.productions?.future || {});
  const utility =
    utilityArray.length === 0
      ? 'N/A'
      : `${(
          (utilityArray.filter((useful) => useful).length /
            utilityArray.length) *
          100
        ).toFixed(0)}%`;

  if (questions?.questionValuable) {
    feedbackFragments.push(
      new Paragraph({
        text: `${questions.questionValuable}: ${valuable}`,
      })
    );
  }

  if (questions?.customQuestionD) {
    feedbackFragments.push(
      new Paragraph({
        text: `${questions.customQuestionD}: ${utility}`,
      })
    );
  }

  const otherQuestions = [
    {
      questionLabel: 'customQuestionA',
      productionRef: 'questionAAnswer',
    },
    {
      questionLabel: 'customQuestionB',
      productionRef: 'questionBAnswer',
    },
    {
      questionLabel: 'customQuestionC',
      productionRef: 'questionCAnswer',
    },
  ];

  otherQuestions.forEach(({ questionLabel, productionRef }) => {
    if (questions?.[questionLabel]) {
      const answers = Object.values(
        feedbackData?.productions?.[productionRef] || {}
      );
      if (answers.length > 0) {
        feedbackFragments.push(
          new Paragraph({
            text: '',
          })
        );
        feedbackFragments.push(
          new Paragraph({
            text: questions[questionLabel],
            style: 'Titre2',
          })
        );
        answers.forEach((answer) => {
          feedbackFragments.push(
            new Paragraph({
              text: answer as string,
            })
          );
        });
      }
    }
  });

  return feedbackFragments;
};

export const sessionToDoc = async (
  sessionId: string,
  session: Session,
  activities: Activities,
  displayNameMap: DisplayNameMap,
  organizers: string,
  communityName: string,
  fullExport: boolean,
  docIdsToExport?: string[],
  exportType?: SessionExportType,
  useAI: boolean = false
): Promise<DocFragment[]> => {
  const sessionDate = `${moment(session!.scheduledAt[0]).format(
    i18n.t('misc:dateFormat')
  )} ${i18n.t('misc:to')} ${moment(session!.scheduledAt[0]).format(
    i18n.t('misc:timeFormat')
  )}`;

  const fragments: DocFragment[] = [];

  fragments.push(
    new Paragraph({
      text: session.title,
      style: 'Titre',
    })
  );

  fragments.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `${sessionDate}`,
        }),
      ],
      spacing: {
        before: 100,
      },
    })
  );

  fragments.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `${i18n.t('sessions:OrganizedBy')} `,
        }),
        new TextRun({
          text: organizers,
          bold: true,
        }),
        new TextRun({
          text: ` ${i18n.t('common:from')} `,
        }),
        new TextRun({
          text: communityName,
          bold: true,
        }),
      ],
      spacing: {
        after: 200,
      },
    })
  );

  if (session.description) {
    fragments.push(
      new Paragraph({
        text: i18n.t('common:Description'),
        style: 'Titre3',
      })
    );
    fragments.push(
      new Paragraph({
        text: session.description,
      })
    );
  }

  if (session.goals && session.goals.length > 0) {
    fragments.push(
      new Paragraph({
        text: i18n.t('editor:goals'),
        style: 'Titre3',
      })
    );
    session.goals.forEach((goal) => {
      fragments.push(
        new Paragraph({
          bullet: {
            level: 0,
          },
          text: goal,
        })
      );
    });
  }

  // Comment Table Of content since we cant hide lower level in goolge docs...
  // fragments.push(
  //   new TableOfContents('Summary', {
  //     hyperlink: true,
  //     headingStyleRange: '1-2',
  //   })
  // );

  fragments.push(
    new Paragraph({
      spacing: {
        after: 400,
      },
    })
  );

  if (exportType === 'classic') {
    const activitiesFragments = await activitiesToDoc(
      sessionId,
      session.language || 'fr',
      activities,
      displayNameMap,
      fullExport,
      docIdsToExport,
      useAI
    );

    fragments.push(...activitiesFragments);
  }

  if (exportType === 'byTopics') {
    const topicsFragments = await topicsToDoc(
      sessionId,
      session.language || 'fr',
      activities,
      displayNameMap,
      fullExport,
      docIdsToExport,
      useAI
    );

    fragments.push(...topicsFragments);
  }

  if (fullExport) {
    const feedbacks = await exportFeedbacks(sessionId);
    fragments.push(...feedbacks);
  }

  return fragments;

  // return [
  //   {
  //     headers: {
  //       default: new Header({
  //         children: [new Paragraph(session.title)],
  //       }),
  //     },
  //     footers: {
  //       default: new Footer({
  //         children: [new Paragraph('WAP')],
  //       }),
  //     },
  //     children: fragments,
  //   },
  // ];
};

export type ActivityDataType = {
  infos: {
    name: string;
    visibility: boolean;
  };
  productions: {
    post: string;
    criteria: any[];
    questions: any[];
    votes: any[];
    docs: Record<string, boolean>;
  };
};

export const activityToDoc = async (
  activity: ActivityDataType
): Promise<DocFragment[]> => {
  const productionFragments: DocFragment[] = [];

  if (activity.infos.visibility) {
    if (activity.productions.post) {
      productionFragments.push(
        new Paragraph({
          text: activity.productions.post,
          style: 'Citation',
          spacing: {
            after: 200,
          },
        })
      );
    }

    if (activity.productions.post && activity.productions.criteria.length > 0) {
      activity.productions.criteria.forEach((criterium) => {
        productionFragments.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${criterium.description} :`,
                bold: true,
              }),
              new TextRun({
                text: ` ${
                  criterium.value
                    ? criterium.value
                    : i18n.t('common:noResponse')
                }`,
                shading: {
                  type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                  color: 'FFFFFF',
                  fill: criterium.value ? 'FFFFFF' : 'E69138',
                },
              }),
            ],
            spacing: {
              after: 200,
            },
          })
        );
      });
    }

    if (activity.productions.votes.length > 0) {
      productionFragments.push(
        new Paragraph({
          text: i18n.t('sessions:youLikedPosts'),
          style: 'Titre3',
        })
      );
      const userNames = await Promise.all(
        activity.productions.votes.map(async (vote) => {
          return await fetchUserName(vote.userId);
        })
      );
      activity.productions.votes.forEach((vote, index) => {
        productionFragments.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${userNames[index] || i18n.t('common:unknowUser')} :`,
                bold: true,
              }),
            ],
            spacing: {
              after: 50,
            },
          })
        );
        productionFragments.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${vote.post}`,
              }),
            ],
            style: 'Citation',
            spacing: {
              after: 200,
            },
          })
        );
      });
    }

    if (activity.productions.docs) {
      const docs = await Promise.all(
        Object.keys(activity.productions.docs).map((docId) => getDoc(docId))
      );

      const documents = docs.filter((doc) => doc !== null) as DocWithMeta[];
      const docsExports = await Promise.all(
        documents.map(async (doc) => {
          const cleanDocContent = removeEmptyText(doc.content as TNode[]);
          return [
            new Paragraph({ children: [new TextRun('')] }),
            ...(await serializeFragments(cleanDocContent)),
          ];
        })
      );

      _.flatten(docsExports).forEach((fragment) => {
        productionFragments.push(fragment);
      });
    }

    if (activity.productions.questions.length > 0) {
      productionFragments.push(
        new Paragraph({
          text: i18n.t('sessions:personalSessionFeedbackTitle'),
          style: 'Titre3',
        })
      );
      activity.productions.questions.forEach((question) => {
        productionFragments.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${question.description} `,
              }),
              new TextRun({
                text:
                  question.type === 'boolean'
                    ? question.value
                      ? i18n.t('common:yes')
                      : i18n.t('common:no')
                    : question.value,
                bold: true,
              }),
            ],
            spacing: {
              after: 200,
            },
          })
        );
      });
    }

    const noData =
      !activity.productions?.post &&
      !_.size(activity.productions?.votes) &&
      !_.size(activity.productions?.docs) &&
      !_.size(activity.productions?.questions);

    if (noData) {
      productionFragments.push(
        new Paragraph({
          children: [
            new TextRun({
              text: i18n.t('sessions:noInformationForThisactivity'),
              bold: true,
            }),
          ],
          spacing: {
            after: 200,
          },
        })
      );
    }
  } else {
    productionFragments.push(
      new Paragraph({
        children: [
          new TextRun({
            text: i18n.t('sessions:documentPrivateDescription'),
            bold: true,
          }),
        ],
        spacing: {
          after: 200,
        },
      })
    );
  }

  return [
    new Paragraph({
      text: activity.infos.name,
      style: 'Titre1',
    }),
    ...productionFragments,
  ];
};

export const buildDoc = (title: string, fragments: DocFragment[]) => {
  return [
    {
      headers: {
        default: new Header({
          children: [new Paragraph(title)],
        }),
      },
      footers: {
        default: new Footer({
          children: [new Paragraph('WAP')],
        }),
      },
      children: fragments,
    },
  ];
};

export const exportSingleDoc = async (metaId?: string, value?: TNode[]) => {
  const meta = metaId
    ? await fetchData<DocMeta>(`contentDocsMeta/${metaId}`)
    : null;

  const docData = meta
    ? await fetchData<TNode[]>(`contentDocs/${meta.current}`)
    : value;

  if (docData) {
    console.log(docData);
    const section = await serialize(docData);
    const doc = sectionsToDoc([section]);
    const name = meta?.title || meta?.name || i18n.t('misc:unnamed');
    await saveDoc(name!, doc);
  }
};

export type ExportSessionOptions = {
  sessionId: string;
  participants: Users;
  fullExport: boolean;
  docIdsToExport?: string[];
  exportType?: SessionExportType;
  useAI?: boolean;
  anonymize?: boolean;
};

export const exportSessionAsDocument = async ({
  sessionId,
  participants,
  fullExport,
  docIdsToExport = undefined,
  exportType = 'classic',
  useAI = false,
  anonymize = false,
}: ExportSessionOptions) => {
  const session = await fetchData<Session>(`sessionsNext/${sessionId}`);
  const sessionTemplate = await fetchData<SessionTemplate>(
    `sessionsNextTemplates/${sessionId}`
  );
  const communityName: string =
    (await fetchData(`communities/${session?.communityId}/name`)) || '';

  const organizersId = await fetchData<TrueSet>(
    `/sessionsNextData/${sessionId}/users/organizers`
  );

  const organizers: string[] = [];
  await sequencialForEach(Object.keys(organizersId || {}), async (userId) => {
    const organizerName: string =
      (await fetchData(`/users/${userId}/name`)) || i18n.t('common:unknowUser');
    organizers.push(organizerName);
  });

  const organizersLabel =
    organizers.length > 0
      ? `${organizers[0]} ${
          organizers.length > 1
            ? `${i18n.t('misc:and')} ${
                organizers.length > 2
                  ? i18n.t('common:XOtherPeople', {
                      count: organizers.length - 1,
                    })
                  : organizers[1]
              }`
            : ``
        }`
      : '';

  // Create display name mapping once for the entire session
  const displayNameMap = createDisplayNameMap(participants, anonymize);

  if (session && sessionTemplate) {
    const fragments = await sessionToDoc(
      sessionId,
      session,
      sessionTemplate.activities,
      displayNameMap,
      organizersLabel,
      communityName,
      fullExport,
      docIdsToExport,
      exportType,
      useAI
    );

    const sections = [
      {
        headers: {
          default: new Header({
            children: [new Paragraph(session.title)],
          }),
        },
        footers: {
          default: new Footer({
            children: [new Paragraph('WAP')],
          }),
        },
        children: fragments,
      },
    ];

    const doc = sectionsToDoc(sections);

    const datetime = moment(session.scheduledAt[0]);

    const filename = `WAP_${session.title}_${datetime
      .format(i18n.t('misc:dateFormatShort'))
      .replace('/', '_')}${anonymize ? '_anonymous' : ''}_productions`;

    return {
      doc,
      filename,
    };
  }
};

export const exportSessionDocs = async (options: ExportSessionOptions) => {
  const response = await exportSessionAsDocument(options);

  if (response) {
    const { doc, filename } = response;

    const blob = await Packer.toBlob(doc);

    return saveAs(blob, `${filename}.docx`);
  }
};

export const exportDocAfterAIFullSynthesis = async ({
  sessionId,
  participants,
  synthesisType,
}: {
  sessionId: string;
  participants: Users;
  synthesisType: 'global' | 'inspirations';
}) => {
  const response = await exportSessionAsDocument({
    sessionId,
    participants,
    fullExport: true,
    anonymize: true,
  });

  const prompt = await fetchData<string>(
    `aiSynthesisPrompts/${
      synthesisType === 'global' ? 'globalPrompt' : 'inspirationsPrompt'
    }`
  );

  if (!prompt) {
    throw new Error('Prompt could not be retrieved');
  }

  const session = await fetchData<Session>(`sessionsNext/${sessionId}`);

  // remplace template
  const context = await getContextFromHints(
    sessionId,
    'post',
    'none',
    ['question'],
    {},
    true
  );

  const fullContext = {
    ...(context as Record<string, string>),
    session_title: session?.title ?? '',
  };

  let augmentedPrompt = templateReplace(prompt, fullContext);

  if (response) {
    const { doc, filename } = response;

    const blob = await Packer.toBase64String(doc);

    const result = await promptAIForSynthesis({
      fileContent: blob,
      filename: `${filename}.docx`,
      prompt: augmentedPrompt,
    });

    const { answer, attachment } = result;

    if (attachment) {
      const { content, filename: newFileName } = attachment;

      const fileParts = newFileName.split('/');
      const name = fileParts[fileParts.length - 1];

      let array = Uint8Array.from(content, (c: string) => c.charCodeAt(0));

      const newBlob = new Blob([array], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      await saveAs(newBlob, `${name}_${synthesisType}.docx`);

      return;
    }

    return answer.replace(/【[^【】]*】/g, '');
  }
};
