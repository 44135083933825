import { useUserContextWithDefaults } from 'contexts/UserContext';
import { Form, Formik } from 'formik';
import AutoSave from 'frameworks/formik/AutoSave';
import ListItemInput from 'frameworks/formik/ListItemInput';
import TextArea from 'frameworks/formik/TextArea';
import {
  saveConfidentialityList,
  useConfidentialityList,
  useAISynthesisPrompts,
  saveAISynthesisPrompts,
} from 'model/parameters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from 'screens/LoadingScreen';

const AppAdminGlobalParameters = () => {
  const { t } = useTranslation();
  const { userId } = useUserContextWithDefaults();

  const [list, listLoaded] = useConfidentialityList(userId);
  const [prompts, promptsLoaded] = useAISynthesisPrompts(userId);

  const isLoaded = listLoaded && promptsLoaded;

  const handleSubmit = async (values: {
    list: string[];
    globalPrompt: string;
    inspirationsPrompt: string;
  }) => {
    await Promise.all([
      saveConfidentialityList(values.list.filter((elem) => elem !== '')),
      saveAISynthesisPrompts(values.globalPrompt, values.inspirationsPrompt),
    ]);
  };

  return isLoaded ? (
    <Formik
      initialValues={{
        list,
        globalPrompt: prompts.globalPrompt,
        inspirationsPrompt: prompts.inspirationsPrompt,
      }}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Form className="space-y-8">
            <AutoSave />
            <div>
              <h3 className="text-lg font-medium mb-4">
                {t('misc:ConfidentialitySettings')}
              </h3>
              <ListItemInput
                name="list"
                label={t('misc:ConfidentialityList')}
                placeholder={t('misc:ExDomain')}
              />
            </div>

            <div>
              <h3 className="text-lg font-medium mb-4">
                {t('misc:AISynthesisSettings')}
              </h3>
              <div className="space-y-4">
                <div>
                  <TextArea
                    name="globalPrompt"
                    label={t('misc:GlobalSynthesisPrompt')}
                    placeholder={t('misc:GlobalSynthesisPromptPlaceholder')}
                  />
                  <div className="text-sm text-gray-500 mt-1">
                    {t('misc:AISynthesisPromptDescription')}
                  </div>
                </div>
                <div>
                  <TextArea
                    name="inspirationsPrompt"
                    label={t('misc:InspirationsSynthesisPrompt')}
                    placeholder={t(
                      'misc:InspirationsSynthesisPromptPlaceholder'
                    )}
                  />
                  <div className="text-sm text-gray-500 mt-1">
                    {t('misc:AISynthesisPromptDescription')}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <LoadingScreen />
  );
};

export default AppAdminGlobalParameters;
